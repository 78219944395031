import TextField from 'components/Form/TextField/TextField';
import React, { useCallback, useEffect, useState } from 'react';
import SeachIcon from "assets/images/market-place/servicesIcon/searchIcon.png";
import { ReactComponent as EditIcon } from "assets/images/umrah-operations/edit-icon.svg";
import { ReactComponent as DeleteIcon } from "assets/images/umrah-operations/trash-icon.svg";
import Locale from 'translations';
import { useSBSState } from 'context/global';
import AddEditMovementOfficerModal from './OfficerModal';
import { fetchGroupsLookup, fetchOfficersList, unlinkOfficer } from 'services/operationStatement';
import { debounce } from "lodash";
import Pagination from 'components/Pagination';
import { handleResponesNotification } from 'modules/UmrahOperations/helper/accountingHelpers';
import ShowForPermission from 'helpers/showForPermission';


const saudiFlagUUID = "d68c87b9-9a70-4032-8226-aaab6687442a";
const initialModalData = {
  name: "",
  email: "",
  password: "",
  password_confirmation: "",
  phone_code: {
    flag: saudiFlagUUID,
    label: "+966",
    value: "+966",
    id: "+966",
  },
  phone: "",
  group: null,
}


export default function OfficialMovementManagement({ officersList, officersMeta, setMovementOfficers }) {
  // hooks
  const { marketPlace, CRM } = Locale;
  const { locale } = useSBSState();
  // states
  const [searchTerm, setSearchTerm] = useState(null);
  const [officerModal, setOfficerModal] = useState({
    isOpen: false,
    id: null,
    data: initialModalData,
    type: null
  });
  const [groupsLookup, setGroupsLookup] = useState(null);

  // ** functions

  // toggle officer modal
  function toggleOfficerModal({ data, type, id }) {
    setOfficerModal(prev => {
      return {
        isOpen: !prev.isOpen,
        data,
        type,
        id
      }
    });
  }



  // handle delete officer
  async function handleDeleteOfficer(officerId) {
    if (!officerId) return;
    const res = await unlinkOfficer(officerId);
    let message;
    if (res?.status === 200) {
      message = "officer Unlinked Succesfully";
      handleResponesNotification({ alertType: "success", message, title: "" });
      getOfficersList({});
    }
  }


  // get Officers list
  const getOfficersList = useCallback(async (params) => {
    const res = await fetchOfficersList(params);
    if (res?.status === 200) {
      setMovementOfficers({ list: res?.data?.data, meta: res?.data?.meta });
    }
  }, [setMovementOfficers]);


  // handle go to page 
  function goTo(page) {
    getOfficersList({ page })
  };


  // options for select group field
  useEffect(() => {
    async function getGroupsLookup() {
      const res = await fetchGroupsLookup();
      if (res?.status === 200) {
        setGroupsLookup(res?.data);
      }
    };

    getGroupsLookup();
  }, []);

  useEffect(() => {
    if (!officersList) {
      getOfficersList({});
    }
  }, [getOfficersList, officersList]);

  // get groups when search term changes
  useEffect(() => {
    const debouncedSearch = debounce(async () => {
      await getOfficersList({ page: null, search: searchTerm });
    }, 500);

    if (searchTerm !== null) {
      debouncedSearch();
    }
    // Cleanup function to cancel pending debounced calls on unmount
    return () => debouncedSearch.cancel();
  }, [getOfficersList, searchTerm]);



  return (
    <div className='movement-tab'>

      <div className="d-flex align-items-center flex-wrap gap-1 justify-content-between mb-3 min-w-120">
        <div className='flex-grow-1 flex-md-grow-0 flex-shrink-0 p-0'>
          <TextField
            type="text"
            id="agent-search"
            name="agent-search"
            label={marketPlace.messages.search}
            placeholder={marketPlace.messages.search}
            isImage={true}
            image={SeachIcon}
            prependImage={true}
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
        </div>
        <ShowForPermission permission="Manage-Official-Management">
          <button 
            className="add-btn align-self-end btn flex-grow-1 flex-md-grow-0 h-44 justify-content-center mb-1/4 min-w-fit-content"
            onClick={() => toggleOfficerModal({ data: initialModalData, type: "add", id: null })}
            disabled={!groupsLookup}
          >
            <i className="fas fa-plus"></i>
            {Locale.movementManagement.addDelegate}
          </button>
        </ShowForPermission>
      </div>


      <ShowForPermission permission="View-Official-Management">
        <div className="table-container">
          <table className="table table-update w-100 mb-0">
            <thead>
              <tr>
                <th scope="col">{Locale.movementManagement.name}</th>
                <th scope="col">{Locale.movementManagement.groupName}</th>
                <th scope="col">{Locale.movementManagement.city}</th>
                <th scope="col">{Locale.movementManagement.email}</th>
                <th scope="col">{Locale.movementManagement.phoneNumber}</th>
                <ShowForPermission permission="Manage-Official-Management">
                  <th scope="col">{Locale.movementManagement.action}</th>
                </ShowForPermission>
              </tr>
            </thead>
            <tbody>
              {officersList?.length > 0
                ? officersList?.map(officer => {
                  return (
                    <tr key={officer?.id}>
                      <td>
                        <span className="text-black-900">{officer?.name || "-"}</span>
                      </td>
                      <td className='data'>
                        {officer?.group?.name || "-"}
                      </td>
                      <td className='data'>
                        {officer?.group?.city?.names[locale] || officer?.group?.city?.name || "-"}
                      </td>
                      <td className='data'>
                        {officer?.email || "-"}
                      </td>
                      <td className='data'>
                        {officer?.phone_code} {officer?.phone}
                      </td>
                      <ShowForPermission permission="Manage-Official-Management">
                        <td className="table-actions">
                          <button className="btn shadow-none p-0"
                            onClick={() => toggleOfficerModal({
                              data: {
                                name: officer?.name,
                                email: officer?.email,
                                phone_code: officer?.phone_code,
                                phone: officer?.phone,
                                group: { id: officer?.group?.id, name: officer?.group?.name },
                              },
                              type: "edit",
                              id: officer?.id
                            })}
                          >
                            <EditIcon />
                          </button>
                          <button className="btn shadow-none p-0 delete-btn" onClick={() => handleDeleteOfficer(officer?.id)}>
                            <DeleteIcon />
                          </button>
                        </td>
                      </ShowForPermission>
                    </tr>
                  )
                })
                :
                <tr>
                  <td colSpan={6} className="text-center">{CRM.NoData}</td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </ShowForPermission>

      {/* list Pagination */}
      <div className="mt-3">
        <Pagination
          info={officersMeta}
          goTo={goTo}
        />
      </div>

      {/* add Edit officer modal */}
      {officerModal.isOpen && 
        <AddEditMovementOfficerModal
          modalData={officerModal}
          toggleOfficerModal={() => toggleOfficerModal({ data: null, type: null })}
          setOfficerModal={setOfficerModal}
          getOfficersList={getOfficersList}
          groupsLookup={groupsLookup}
        />
      }
    </div>
  );
}

