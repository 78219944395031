import SelectField from "components/Form/SelectField/SelectField";
import { useEffect, useState } from "react";
import validate from "helpers/validate";
import Locale from "translations";
import TextField from "components/Form/TextField/TextField";
import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import { useSBSDispatch, useSBSState } from "context/global";
import { getAgentList } from "services/operationStatement";
import PaxInput from "modules/UmrahOperations/shared/PaxInput";
import { useHistory, useParams } from "react-router-dom";

import { ReactComponent as CloseIcon } from "assets/images/umrah-operations/close-icon.svg";
import { fetchCompanyProfile } from 'services/profile';
import { getOperationsLookup } from 'modules/UmrahOperations/helper/operationsLookup';
import CheckBox from 'components/chekbox';
import { fetchCountries } from 'services/lookups';
import { formatViewOperationStatement } from 'modules/UmrahOperations/helper/formatUmrahOperations';


export default function OperationDetails({
	operationHasSent,
	OperationStatement,
	setOperationStatement,
	isDisabled,
	formSubmition,
	errors,
	setErrors,
	submitProduct
}) {
	const { operationStatement, productsBuilder, userDetails } = Locale;
	const { allCountries, token, companyInfo, isAuth, locale } = useSBSState();
	const dispatch = useSBSDispatch();
	const history = useHistory();
	const { reference, id, bundle } = useParams();
	const { statementTypeList } = getOperationsLookup(Locale);


	//** states
	const [listAuto, setListAuto] = useState([]);

	// drived valuse
	const statementId = reference || id;
	const operation_details = OperationStatement.operation_details;
	const filesUrl = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";
	const isPreLogin = token ? false : true;


	// useEffect(() => {

	// 	async function CreateStatementMainDetails() {
	// 		if (isFormValid(errors)) {
	// 			let response;
	// 			if (statementId === undefined) {
	// 				response = await addNewStatements(data);
	// 				if (response.status === 200 || response.status === 201) {
	// 					history.push(`/${bundle}/operation-view/${response?.data?.data?.reference_number}`)

	// 					let newData = await formatViewOperationStatement(response.data.data, Locale);
	// 					setOperationStatement(newData)
	// 					// history.push("/operations/operation-view/" + response?.data?.data?.reference_number);
	// 				}
	// 			} else {
	// 				if (!window.location.pathname.includes("public")) {
	// 					response = await updateNewStatements(statementId, data);
	// 				} else {
	// 					response = await addNewStatementsPublic(statementId, data);
	// 				}
	// 			}
	// 		}
	// 	}

	// 	if (isFirst) {
	// 		CreateStatementMainDetails()
	// 	}
	// }, [isErrorLoaded]);
	// function responseCallback(response) {
	// 	debugger
	// 	if (!window.location.pathname.includes("public") && response?.status === 201) {
	// 		history.push(`/${bundle}/operation-view/${response?.data?.data?.reference_number}`);
	// 		return
	// 	}
	// }


	const getListAuto = async (inputValue) => {
		if (inputValue.length > 2) {
			const Agents = await getAgentList({ name: inputValue });

			let result =
				Agents.data.data && Agents.data.data.length > 0
					? Agents.data.data.map((item) => {
						return {
							...item,
							id: item.id,
							country: { ...item.country, flag: false },
							name: item.name + " - " + item.country.name[locale],
							value: item.id,
							label: item.name + " - " + item.country.name[locale],
						};
					})
					: null;
			setListAuto(result);
		}
	};

	const getCountires = async () => {
		const countries = await fetchCountries()
		dispatch({
			type: "getCountries",
			payload: countries,
		});
	}

	// fetch profile company data
	useEffect(() => {
		async function fetchCompanyProfileData() {
			const companypProfileRes = await fetchCompanyProfile();
			if (companypProfileRes?.company_category === "tourism_company") {
				setOperationStatement({
					...OperationStatement,
					operation_details: {
						...operation_details,
						country: {
							id: companypProfileRes.country?.id,
							name: companypProfileRes.country?.name,
						},
						email: companypProfileRes.business_email,
						phoneCode: allCountries.find(item => item?.phone_code === companypProfileRes.phone?.phone_code),
						phone: companypProfileRes.phone?.phone,
						agent: { name: companypProfileRes?.name, id: companypProfileRes?.id },
						agent_id: { name: companypProfileRes?.name, id: companypProfileRes?.id },
						company_category: companypProfileRes?.company_category,
					},
				});
			}
		}
		if (!statementId && allCountries && allCountries.length > 0 && !window.location.pathname.includes("public")) {
			fetchCompanyProfileData();
		}
	}, [allCountries]);

	useEffect(() => {
		if (!isAuth) {
			getCountires()
		}


	}, []);

	return (
		<>
			<div className="col-md-12 OperationDetails">
				<div className="row">
					<div className="col-12 col-md-3">
						{/* Statement Type */}
						<div className="form-group full-input">
							<SelectField
								disabled={operationHasSent || isDisabled}
								label={operationStatement.StatementType + "*"}
								value={operation_details?.statement_type?.name}
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(
											{ name: "statement_type", value: e.value },
											{ required: true }
										),
									});
									setOperationStatement({
										...OperationStatement,
										operation_details: {
											...operation_details,
											statement_type: e,
										},
									});
								}}
								options={statementTypeList}
								placeholder={productsBuilder.select}
								onBlur={() =>
									setErrors({
										...errors,
										...validate(
											{
												name: "statement_type",
												value: operation_details?.statement_type?.id,
											},
											{ required: true }
										),
									})
								}
								color={errors?.statement_type?.required ? "danger" : ""}
								errors={errors?.statement_type}
							/>
						</div>
					</div>

					{/* File Number */}
					<div className="col-12 col-md-3">
						<div className="form-group full-input">
							<TextField
								disabled={isDisabled || operationHasSent}
								placeholder={operationStatement.TypeFileNo}
								label={operationStatement.FileNo}
								name="file_number"
								value={operation_details?.file_number}
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(e.target, { required: true }),
									});
									setOperationStatement({
										...OperationStatement,
										operation_details: {
											...operation_details,
											file_number: e.target.value,
										},
									});
								}}
								color={errors?.file_number?.required ? "danger" : ""}
								errors={errors?.file_number}
							/>
						</div>
					</div>

					<div className="col-md-3 d-flex align-items-center mt-4 modal-team ">
						<div className="form-check-modal" style={{ userSelect: "none" }}>
							<CheckBox
								text={operationStatement?.without_movements}
								onChange={(e) => {
									setOperationStatement({
										...OperationStatement,
										operation_details: {
											...operation_details,
											without_movements: !operation_details?.without_movements,
										},
									});
								}}
							/>
						</div>
					</div>
				</div>

				{/* agent details */}
				<div className="row mt-3">
					{/* agent name */}
					<div className="col-12 col-md-3 position-relative">
						<AutoCompleteField
							disabled={isPreLogin || isDisabled || operationHasSent || companyInfo?.company_category === "tourism_company"}
							listAuto={listAuto}
							setListAuto={setListAuto}
							getListAuto={getListAuto}
							label={operationStatement.Agent + "*"}
							isSearchable={true}
							flag={false}
							isImage={false}
							placeholder={operationStatement.TypeAgentName}
							value={operation_details?.agent?.name || ""}
							showX={!(isPreLogin || isDisabled || operationHasSent || companyInfo?.company_category === "tourism_company")}
							// onFocus={clearSearchText}
							// onBlur={() =>
							// 	setHotel({ ...hotel, goingTo: goingToPrevValue.current })
							// }
							onChange={(e) =>
								setOperationStatement({
									...OperationStatement,
									operation_details: {
										...operation_details,
										agent: { name: e },
									},
								})
							}
							onSelectValue={(e) => {
								if (e) {
									setOperationStatement({
										...OperationStatement,
										operation_details: {
											...operation_details,
											agent: e,
											agent_id: e.id,
											email: e.email,
											phone: e.phone,
											// phoneCode: {
											// 	phone_code: e?.phone_code
											// },
											phoneCode: e.phone_code,
											responsible_name: e.responsible_name,
											responsible_phone: e.responsible_phone,
											// responsible_phone_code: {
											// 	phone_code: e?.responsible_phone_code,
											// },
											responsible_phone_code: e?.responsible_phone_code,
											country: {
												...e.country,
												id: e.country.country_id,
												value: e.country.country_id,
												name: e.country.name[locale],
												label: e.country.name[locale],
											},
										},
									});
								}
								else {
									setOperationStatement({
										...OperationStatement,
										operation_details: {
											...operation_details,
											agent: { name: null },
											agent_id: null,
											email: "",
											phone: "",
											phoneCode: null,
											responsible_name: "",
											responsible_phone: "",
											responsible_phone_code: null,
											country: null,
										},
									});
								}
								setErrors({
									...errors,
									...validate({ name: "agent", value: e }, { required: true }),
								});
							}}
							color={errors?.agent?.required ? "danger" : ""}
							errors={errors?.agent}
							showMore={false}
						/>
					</div>
					{/* agent country */}
					<div className="col-12 col-md-3">
						<div className="form-group full-input">
							<SelectField
								label={operationStatement.country + "*"}
								value={operation_details?.country?.name}
								disabled={operation_details.agent_id != null || isDisabled || operationHasSent}
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(
											{ name: "country", value: e.value },
											{ required: true }
										),
									});
									setOperationStatement({
										...OperationStatement,
										operation_details: {
											...operation_details,
											country: {
												id: e.value,
												name: e.label,
											},
										},
									});
								}}
								options={allCountries}
								placeholder={productsBuilder.select}
								onBlur={() =>
									setErrors({
										...errors,
										...validate(
											{
												name: "country",
												value: operation_details?.country?.id,
											},
											{ required: true }
										),
									})
								}
								color={errors?.country?.required ? "danger" : ""}
								errors={errors?.country}
							/>
						</div>
					</div>
					{/* agent email */}
					<div className="col-12 col-md-3">
						<div className="form-group full-input">
							<TextField
								placeholder={operationStatement.emailAddressPlaceholder}
								label={operationStatement.emailAddress + "*"}
								disabled={operation_details.agent_id != null || isDisabled || operationHasSent}
								name="email"
								value={operation_details.email}
								onChange={(e) => {
									setErrors({
										...errors,
										...validate(e.target, { required: true }),
									});
									setOperationStatement({
										...OperationStatement,
										operation_details: {
											...operation_details,
											email: e.target.value,
										},
									});
								}}
								color={errors?.email?.required ? "danger" : ""}
								errors={errors?.email}
							/>
						</div>
					</div>
					{/* agent phone */}
					<div className="col-12 col-md-3 phone">
						{/* phone code  */}
						<div className="phone_code phone_code-fix">
							<SelectField
								name="phonecode"
								id="phonecode"
								disabled={operation_details.agent_id != null || isDisabled || operationHasSent}
								// value={operation_details.phoneCode?.phone_code}
								value={
									operation_details?.phoneCode?.flag ? (
										<img
											src={`${filesUrl}/${operation_details?.phoneCode?.flag}`}
											width="30px"
											alt=""
										/>
									) : (
										// operation_details?.phoneCode?.phone_code || null
										operation_details?.phoneCode?.phone_code || operation_details?.phoneCode
									)
								}
								onChange={(e) => {
									setOperationStatement({
										...OperationStatement,
										operation_details: {
											...operation_details,
											phoneCode: e,
										},
									});
									setErrors({
										...errors,
										...validate(
											{ name: "phoneCode", value: e },
											{ required: true }
										),
									});
								}}
								options={allCountries}
								color={errors?.phoneCode?.required ? "danger" : ""}
							/>
						</div>
						<TextField
							disabled={operation_details.agent_id != null || isDisabled || operationHasSent}
							label={userDetails.phoneNumber + "*"}
							placeholder={userDetails.phoneNumberPlaceholder}
							type="number"
							name="phone"
							id="phone"
							className="phone_number_textfield control-field__input w-100"
							value={operation_details?.phone}
							min={9}
							max={13}
							onWheel={(e) => e.currentTarget.blur()}
							onChange={(e) => {
								setOperationStatement({
									...OperationStatement,
									operation_details: {
										...operation_details,
										phone: e.target.value,
									},
								});

								setErrors({
									...errors,
									...validate(
										{ name: "phone", value: e.target.value },
										{ required: true, min: 9, max: 13 }
									),
								});
							}}
							errors={errors?.phone}
							color={
								errors?.phone?.required ||
									errors?.phone?.min ||
									errors?.phone?.max
									? "danger"
									: ""
							}
						/>
					</div>
				</div>

				<div className="row mt-3">
					{/* Traveler Pax (adults, children, infants) count */}
					<div className="col-12 px-3">
						<div className="row">
							{/* Traveler adults count */}
							<div className="col-12 col-md-2">
								<PaxInput
									title={operationStatement.TravelerPax}
									paxTypeText={operationStatement.adults}
									value={operation_details.adults}
									isDisabled={operationHasSent || isDisabled}
									handleDecrease={() => {
										setOperationStatement({
											...OperationStatement,
											operation_details: {
												...operation_details,
												adults: +operation_details?.adults - 1,
											},
										});
									}}
									handleIncrease={() => {
										setOperationStatement({
											...OperationStatement,
											operation_details: {
												...operation_details,
												adults: +operation_details?.adults + 1,
											},
										});
									}}
									onChange={(value) => {
										setOperationStatement({
											...OperationStatement,
											operation_details: {
												...operation_details,
												adults: value,
											},
										});
									}}
								/>
							</div>

							{/* Traveler children count */}
							<div className="col-12 col-md-2">
								<PaxInput
									paxTypeText={operationStatement.children}
									value={operation_details.children}
									isDisabled={operationHasSent || isDisabled}
									handleDecrease={() => {
										setOperationStatement({
											...OperationStatement,
											operation_details: {
												...operation_details,
												children: +operation_details?.children - 1,
											},
										});
									}}
									handleIncrease={() => {
										setOperationStatement({
											...OperationStatement,
											operation_details: {
												...operation_details,
												children: +operation_details?.children + 1,
											},
										});
									}}
									onChange={(value) => {
										setOperationStatement({
											...OperationStatement,
											operation_details: {
												...operation_details,
												children: value,
											},
										});
									}}
								/>
							</div>

							{/* Traveler infants count */}
							<div className="col-12 col-md-2">
								<PaxInput
									paxTypeText={operationStatement.infant}
									value={operation_details.infants}
									isDisabled={operationHasSent || isDisabled}
									handleDecrease={() => {
										setOperationStatement({
											...OperationStatement,
											operation_details: {
												...operation_details,
												infants: +operation_details?.infants - 1,
											},
										});
									}}
									handleIncrease={() => {
										setOperationStatement({
											...OperationStatement,
											operation_details: {
												...operation_details,
												infants: +operation_details?.infants + 1,
											},
										});
									}}
									onChange={(value) => {
										setOperationStatement({
											...OperationStatement,
											operation_details: {
												...operation_details,
												infants: value,
											},
										});
									}}
								/>
							</div>

							{/* supervisor Name */}
							<div className="supervisor-name col-12 col-md-3">
								<div className="form-group full-input">
									<TextField
										disabled={isDisabled}
										placeholder={operationStatement.supervisorName}
										label={operationStatement.supervisorName}
										name="responsible_name"
										value={operation_details.responsible_name}
										onChange={(e) => {
											// setErrors({
											// 	...errors,
											// 	...validate(e.target, { required: true }),
											// });
											setOperationStatement({
												...OperationStatement,
												operation_details: {
													...operation_details,
													responsible_name: e.target.value,
												},
											});
										}}
									// color={errors?.responsible_name?.required ? "danger" : ""}
									// errors={errors?.responsible_name}
									/>
								</div>
							</div>
							{/* supervisor phone */}
							<div className="supervisor-phone col-12 col-md-3 phone">
								{/* phone code  */}
								<div className="phone_code">
									<SelectField
										name="responsible_phone_code"
										id="responsible_phone_code"
										disabled={isDisabled}
										value={
											operation_details?.responsible_phone_code?.flag ? (
												<img
													src={`${filesUrl}/${operation_details?.responsible_phone_code?.flag}`}
													width="30px"
													alt=""
												/>
											) : (
												operation_details.responsible_phone_code?.phone_code || operation_details.responsible_phone_code
											)
										}
										// value={operation_details.responsible_phone_code?.phone_code}
										onChange={(e) => {
											console.log(e)
											setOperationStatement({
												...OperationStatement,
												operation_details: {
													...operation_details,
													responsible_phone_code: e,
												},
											});
											// setErrors({
											// 	...errors,
											// 	...validate(
											// 		{ name: "responsible_phone_code", value: e },
											// 		{ required: true }
											// 	),
											// });
										}}
										options={allCountries}
									// color={errors?.responsible_phone_code?.required ? "danger" : ""}
									/>
								</div>
								<TextField
									label={userDetails.phoneNumber}
									placeholder={userDetails.phoneNumberPlaceholder}
									type="number"
									name="responsible_phone"
									disabled={isDisabled}
									id="responsible_phone"
									className="phone_number_textfield control-field__input w-100"
									value={operation_details?.responsible_phone}
									min={9}
									max={13}
									onWheel={(e) => e.currentTarget.blur()}
									onChange={(e) => {
										setOperationStatement({
											...OperationStatement,
											operation_details: {
												...operation_details,
												responsible_phone: e.target.value,
											},
										});

										// setErrors({
										// 	...errors,
										// 	...validate(
										// 		{ name: "responsible_phone", value: e.target.value },
										// 		{ required: true, min: 9, max: 13 }
										// 	),
										// });
									}}
								// errors={errors?.responsible_phone}
								// color={
								// 	errors?.responsible_phone?.required ||
								// 		errors?.responsible_phone?.min ||
								// 		errors?.responsible_phone?.max
								// 		? "danger"
								// 		: ""
								// }
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="d-flex flex-wrap gap-1 justify-content-between mt-3 w-100">
				<button
					className="btn close-btn flex-grow-1 flex-grow-md-0 flex-md-grow-0 flex-sm-grow-0 justify-content-center min-w-fit-content py-2"
					onClick={() => history.push(`/${bundle}/operation-statement`)}
				>
					<CloseIcon />
					<span className="mx-2">{operationStatement.Close}</span>
				</button>
				{!operationHasSent ?
					<div >
						<button
							className="align-item-center mb-2 bg-nxt btn-Product-Filter flex-grow-1 flex-md-grow-0 flex-sm-grow-0 justify-content-center max-width-fit-content min-w-fit-content py-2"
							disabled={isDisabled}
							onClick={() => {
								// setSaveTabName("operation-details")
								formSubmition({ operation_details })
							}}
						>
							{productsBuilder.Save}
						</button>
						{!isAuth &&
							<button
								className="btn-Product-Filter bg-nxt justify-content-center"
								onClick={() => {
									submitProduct();
								}}
								disabled={!operation_details?.statement_type?.id}
							>
								{productsBuilder.submit}
							</button>
						}
					</div>
					: null}
			</div>
		</>
	);
}
