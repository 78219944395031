import { useCallback, useEffect, useState } from 'react';
// React-Router-Dom
import { useHistory } from 'react-router-dom';
// Lodash
import _ from "lodash";
// Translations
import Locale from 'translations'
// ReactStarp
import { Collapse } from 'reactstrap';
// Context
// import { useSBSState } from 'context/global';
// Components
import TextField from 'components/Form/TextField/TextField';
import MultiTagsTextField from './MultiTagsTextField';
import useDidMountEffect from 'hooks/useDidMountEffect ';
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
// Shared Components
import HotelTranslate from './HotelTranslate';
import FlightTranslate from './FlightTranslate';
import TransferTranslate from './TransferTranslate';
import AttractionTranslate from './AttractionTranslate';
// Custom Hooks
import useSharedData from '../constant/useSharedData';
// Helpler
import validate, { isFormValid } from 'helpers/validate';
// Assets
import { ReactComponent as CloseIcon } from "assets/images/serviceBuilder/x.svg";
import { ReactComponent as MagicIcon } from 'assets/images/serviceBuilder/magic-translate.svg'
// Services
import { addServiceTranslate } from 'services/serviveBuilder';
import { removeHTMLTags } from 'modules/WebBuilder-V2/helpers/removeHtmlTag';
// -------------------------------------------------------------------------------------------------

function PackageTranslate({ data, setData, selectedLang, }) {

  // *** hooks
  const { Service_Builder } = Locale

  const {
    handleAutoTranslate,
    prepareTranslateBuilderFlight,
    prepareTranslateBuilderHotel,
    prepareTranslateBuilderTransfer,
    prepareTranslateBuilderAttraction,
    prepareSendHotelsTranslate,
    prepareSendFlightTranslate,
    prepareSendTransferTranslate,
    prepareSendAttractionTranslate,
    servicesToShow
  } = useSharedData()

  const history = useHistory()

  const servicesItems = servicesToShow(data?.items)

  // *** States
  const [errors, setErrors] = useState({});

  const [inclusions, setInclusions] = useState('')

  const [exclusions, setExclusions] = useState('')

  const [isOpenIndex, setIsOpenIndex] = useState();

  const [acticeItem, setActiveItem] = useState(servicesItems?.[0]);

  const [packageData, setPackageData] = useState();

  const [formData, setFormData] = useState()

  const [isSubmitForm, setIsSubmitForm] = useState()

  const requiredInputs = [
    `name_${selectedLang}`,
    `inclusions_${selectedLang}`,
    `exclusions_${selectedLang}`,
    `package_description_${selectedLang}`,
    `package_policy_${selectedLang}`,
    `package_terms_${selectedLang}`,
  ]

  // *** Fuctions

  function handlePackageInputsChanges({ key, value }, index) {
    let formDataClone = _.cloneDeep(formData)
    if (key.startsWith('builder')) {
      if (formDataClone[key]?.length >= 1) {
        formDataClone[key][index] = value
      } else {
        formDataClone[key] = []
        formDataClone[key][index] = value
      }
      setFormData({
        ...formDataClone,
      })

    } else {
      formDataClone[`${key}`] = value;
      setFormData({ ...formDataClone })
    }
  }


  const handleAddInclusion = () => {
    let formDataClone = _.cloneDeep(formData)
    if (inclusions !== '') {
      setFormData({
        ...formDataClone,
        [`inclusions_${selectedLang}`]: formDataClone?.[`inclusions_${selectedLang}`] ?
          [...formDataClone?.[`inclusions_${selectedLang}`], inclusions]
          : [inclusions]
      })
      setInclusions('')
    }
  }

  const handleRemoveInclusion = (index) => {
    let formDataClone = _.cloneDeep(formData)
    formDataClone?.[`inclusions_${selectedLang}`]?.splice(index, 1)
    setFormData({ ...formDataClone })
  }

  const handleAddExclusions = () => {
    let formDataClone = _.cloneDeep(formData)
    if (exclusions !== '') {
      setFormData({
        ...formDataClone,
        [`exclusions_${selectedLang}`]: formDataClone?.[`exclusions_${selectedLang}`] ?
          [...formDataClone?.[`exclusions_${selectedLang}`], exclusions]
          : [exclusions]
      })
      setExclusions('')
    }
  }

  const handleRemoveExclusions = (index) => {
    let formDataClone = _.cloneDeep(formData)
    formDataClone?.[`exclusions_${selectedLang}`]?.splice(index, 1)
    setFormData({ ...formDataClone })
  }

  const toggleCollapse = (index, activeItem) => {
    setIsOpenIndex((prev) => {
      if (prev === index) return null;
      else return index
    })
    setActiveItem(activeItem)
  }

  function checkFormErrors() {
    let submitError = {};
    requiredInputs.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: formData[key] },
          { required: true }
        )
      }
    })
    if (formData?.['builder_transfer']?.length) {
      const requiredTransferInputs = [
        `name_${selectedLang}`,
        `transfer_description_${selectedLang}`,
        `transfer_policy_${selectedLang}`,
      ]

      formData?.['builder_transfer']?.map((item) => {
        requiredTransferInputs.forEach(key => {
          submitError = {
            ...submitError,
            ...validate(
              { name: key, value: item[key] },
              { required: true }
            )
          }
        })
      })
      return submitError
    }
    setErrors(() => submitError);
    return submitError;
  }

  async function submit(e) {
    e?.preventDefault();
    let formErrors = checkFormErrors();
    // console.log(formErrors)
    setIsSubmitForm(true)
    if (!isFormValid(formErrors)) {
      return
    }
    let requestBody = {
      name: { [selectedLang]: formData?.[`name_${selectedLang}`] },
      inclusions: { [selectedLang]: formData?.[`inclusions_${selectedLang}`] },
      exclusions: { [selectedLang]: formData?.[`exclusions_${selectedLang}`] },
      policy: { [selectedLang]: formData?.[`package_policy_${selectedLang}`] },
      description: { [selectedLang]: formData?.[`package_description_${selectedLang}`] },
      terms: { [selectedLang]: formData?.[`package_terms_${selectedLang}`] },
      services: {
        ...prepareSendHotelsTranslate(formData?.builder_hotel, selectedLang),
        ...prepareSendFlightTranslate(formData?.builder_flight, selectedLang),
        ...prepareSendTransferTranslate(formData?.builder_transfer, selectedLang),
        ...prepareSendAttractionTranslate(formData?.builder_attraction, selectedLang),
      }
    }
    // console.log(requestBody)
    const response = await addServiceTranslate(packageData?.id, 'packages', requestBody);
    if ((response?.status === 200 || response?.status === 201)) {
      history.push('/website/service-builder')
    }
  }

  const showFormData = () => {
    const transformedData = {
      [`name_${selectedLang}`]: data?.name?.[selectedLang],
      [`inclusions_${selectedLang}`]: data?.inclusions?.[selectedLang],
      [`exclusions_${selectedLang}`]: data?.exclusions?.[selectedLang],
      [`package_policy_${selectedLang}`]: removeHTMLTags(data?.policy?.[selectedLang]),
      [`package_description_${selectedLang}`]: removeHTMLTags(data?.description?.[selectedLang]),
      [`package_terms_${selectedLang}`]: removeHTMLTags(data?.terms?.[selectedLang]),
      builder_hotel: prepareTranslateBuilderHotel(data?.items),
      builder_flight: prepareTranslateBuilderFlight(data?.items),
      builder_transfer: prepareTranslateBuilderTransfer(data?.items),
      builder_attraction: prepareTranslateBuilderAttraction(data?.items),
    }
    setFormData(transformedData)
  }

  useDidMountEffect(() => {
    if (formData?.[`inclusions_${selectedLang}`]?.length) {
      setErrors({
        ...errors,
        ...validate(
          { name: `inclusions_${selectedLang}`, value: formData?.[`inclusions_${selectedLang}`]?.length },
          { required: true }
        ),
      });
    }
  }, [formData?.[`inclusions_${selectedLang}`]])

  useDidMountEffect(() => {
    if (formData?.[`exclusions_${selectedLang}`]?.length) {
      setErrors({
        ...errors,
        ...validate(
          { name: `exclusions_${selectedLang}`, value: formData?.[`exclusions_${selectedLang}`]?.length },
          { required: true }
        ),
      });
    }
  }, [formData?.[`exclusions_${selectedLang}`]])

  // useDidMountEffect(() => {
  //   setPackageData({ ...packageData, ...formData })
  //   // setData({ ...packageData, ...formData})
  // }, [formData])

  // useDidMountEffect(() => {
  //   setData(packageData)
  // }, [])

  useEffect(() => {
    setPackageData(data)
    showFormData()
  }, [data, selectedLang])

  // console.log(errors)

  // console.log(formData)
  // console.log(packageData)

  // ---------- JSX Code -------------
  return (
    <div className='service-builder-holder service-translate-holder '>

      <h3 className='title mb-4'>{Service_Builder?.package}</h3>

      <form onSubmit={submit}>

        {/* Package Service Name */}
        <div className='row align-items-center mb-3'>

          <div className='col-11 col-md-5'>
            <p className='mb-1'>{Service_Builder?.service_name} </p>
            <p className='service-info'>{packageData?.name?.['en']} </p>
          </div>

          <div className='col-1 d-flex pointer-event justify-content-center mt-4'>
            <MagicIcon
              onClick={() =>
                handleAutoTranslate({ targetLanguage: selectedLang, text: packageData?.name?.['en'] })
                  .then(result => handlePackageInputsChanges({ key: `name_${selectedLang}`, value: result }))
              }
            />
          </div>

          <div className='col-12 col-md-6 mt-4'>
            <TextField
              type="text"
              hasLabel={false}
              placeholder={Service_Builder?.service_name}
              name={`name_${selectedLang}`}
              id={`name_${selectedLang}`}
              // value={packageData?.[`name_${selectedLang}`]}
              value={formData?.[`name_${selectedLang}`] || ""}
              onChange={(e) => {
                handlePackageInputsChanges({ key: `name_${selectedLang}`, value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: `name_${selectedLang}`, value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.[`name_${selectedLang}`]?.required ? "danger" : ""}
              errors={errors?.[`name_${selectedLang}`]}
            />
          </div>

        </div>

        {/* Package Service Inclusions */}
        <div className='row align-items-start  mb-3'>

          <div className='col-11 col-md-5'>
            <p className='mb-1'> {Service_Builder?.inclusions} </p>
            <div className='d-flex flex-wrap align-items-center'>
              {packageData?.inclusions?.['en']?.map((item) =>
                <p className='pointer-event tag-v2 mx-1 my-1'>
                  {item}
                </p>
              )}
            </div>
          </div>

          <div className='col-1 d-flex pointer-event justify-content-center' />

          <div className='col-12 col-md-6 mt-4'>
            <MultiTagsTextField
              hasLabel={true}
              type="text"
              label={Service_Builder?.inclusions}
              placeholder={Service_Builder?.wifi_free}
              name={`inclusions_${selectedLang}`}
              id={`inclusions_${selectedLang}`}
              value={inclusions}
              onChange={(e) => {
                setInclusions(e.target.value)
              }}
              onClickAddButton={handleAddInclusion}
              // onEnter={(e) => {
              //   if (e.key === "Enter")
              //     handleAddInclusion();
              // }}
              color={errors?.[`inclusions_${selectedLang}`]?.required ? "danger" : ""}
              errors={errors?.[`inclusions_${selectedLang}`]}
            />
            <div className='d-flex flex-wrap mt-2 '>
              {formData?.[`inclusions_${selectedLang}`]?.map((inclusion, index) =>
                <div className='tag-v2 mx-1 my-1' >
                  {inclusion}
                  <span
                    className='pointer-event font-bold'
                    onClick={() => handleRemoveInclusion(index)}
                  >
                    <CloseIcon />
                  </span>
                </div>
              )}
            </div>
          </div>

        </div>

        {/* Package Service Exclusions */}
        <div className='row align-items-start  mb-3'>

          <div className='col-11 col-md-5'>
            <p className='mb-1'> {Service_Builder?.exclusions} </p>
            <div className='d-flex flex-wrap align-items-center'>
              {packageData?.exclusions?.['en']?.map((item) =>
                <p className='pointer-event tag-v2 mx-1 my-1'>
                  {item}
                </p>
              )}
            </div>
          </div>

          <div className='col-1 d-flex pointer-event justify-content-center' />

          <div className='col-12 col-md-6 mt-4'>
            <MultiTagsTextField
              hasLabel={true}
              type="text"
              label={Service_Builder?.exclusions}
              placeholder={Service_Builder?.wifi_free}
              name={`exclusions_${selectedLang}`}
              id={`exclusions_${selectedLang}`}
              value={exclusions}
              onChange={(e) => {
                setExclusions(e.target.value)
              }}
              onClickAddButton={handleAddExclusions}
              // onEnter={(e) => {
              //   if (e.key === "Enter")
              //     handleAddExclusions();
              // }}
              color={errors?.[`exclusions_${selectedLang}`]?.required ? "danger" : ""}
              errors={errors?.[`exclusions_${selectedLang}`]}
            />
            <div className='d-flex flex-wrap mt-2 '>
              {formData?.[`exclusions_${selectedLang}`]?.map((exclusion, index) =>
                <div className='tag-v2 mx-1 my-1' >
                  {exclusion}
                  <span
                    className='pointer-event font-bold'
                    onClick={() => handleRemoveExclusions(index)}
                  >
                    <CloseIcon />
                  </span>
                </div>
              )}
            </div>
          </div>

        </div>

        {/* Package Description*/}
        <div className='row align-items-start mb-3'>

          <div className='col-11 col-md-5'>
            <p className='mb-1'> {Service_Builder?.description} </p>
            <p className='service-info'> {removeHTMLTags(packageData?.description?.['en'])}</p>
          </div>

          <div className='col-1 d-flex pointer-event justify-content-center mt-4 '>
            <MagicIcon
              onClick={() =>
                handleAutoTranslate({ targetLanguage: selectedLang, text: packageData?.description?.['en'] })
                  .then(result => handlePackageInputsChanges({ key: `package_description_${selectedLang}`, value: result }))
              }
            />
          </div>

          <div className='col-12 col-md-6 mt-3'>
            <TextAreaField
              height={'80px'}
              type="text"
              hasLabel={false}
              placeholder={Service_Builder?.description}
              name={`package_description_${selectedLang}`}
              id={`package_description_${selectedLang}`}
              // value={packageData?.[`package_description_${selectedLang}`]}
              value={formData?.[`package_description_${selectedLang}`] || ""}
              onChange={(e) => {
                handlePackageInputsChanges({ key: `package_description_${selectedLang}`, value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: `package_description_${selectedLang}`, value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.[`package_description_${selectedLang}`]?.required ? "danger" : ""}
              errors={errors?.[`package_description_${selectedLang}`]}
            />
          </div>

        </div>

        {/* Package Policy*/}
        <div className='row align-items-start mb-3'>

          <div className='col-11 col-md-5'>
            <p className='mb-1'> {Service_Builder?.policy} </p>
            <p className='service-info'> {removeHTMLTags(packageData?.policy?.['en'])}</p>
          </div>

          <div className='col-1 d-flex pointer-event justify-content-center mt-4 '>
            <MagicIcon
              onClick={() =>
                handleAutoTranslate({ targetLanguage: selectedLang, text: packageData?.policy?.['en'] })
                  .then(result => handlePackageInputsChanges({ key: `package_policy_${selectedLang}`, value: result }))
              }
            />
          </div>

          <div className='col-12 col-md-6 mt-3'>
            <TextAreaField
              height={'80px'}
              type="text"
              hasLabel={false}
              placeholder={Service_Builder?.policy}
              name={`package_policy_${selectedLang}`}
              id={`package_policy_${selectedLang}`}
              // value={packageData?.[`package_policy_${selectedLang}`]}
              value={formData?.[`package_policy_${selectedLang}`] || ""}
              onChange={(e) => {
                handlePackageInputsChanges({ key: `package_policy_${selectedLang}`, value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: `package_policy_${selectedLang}`, value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.[`package_policy_${selectedLang}`]?.required ? "danger" : ""}
              errors={errors?.[`package_policy_${selectedLang}`]}
            />
          </div>

        </div>

        {/* Package Terms And Condition*/}
        <div className='row align-items-start mb-3'>

          <div className='col-11 col-md-5'>
            <p className='mb-1'> {Service_Builder?.termsAndConditions} </p>
            <p className='service-info'> {removeHTMLTags(packageData?.terms?.['en'])}</p>
          </div>

          <div className='col-1 d-flex pointer-event justify-content-center mt-4 '>
            <MagicIcon
              onClick={() =>
                handleAutoTranslate({ targetLanguage: selectedLang, text: packageData?.terms?.['en'] })
                  .then(result => handlePackageInputsChanges({ key: `package_terms_${selectedLang}`, value: result }))
              }
            />
          </div>

          <div className='col-12 col-md-6 mt-3'>
            <TextAreaField
              height={'80px'}
              type="text"
              hasLabel={false}
              placeholder={Service_Builder?.termsAndConditions}
              name={`package_terms_${selectedLang}`}
              id={`package_terms_${selectedLang}`}
              // value={packageData?.[`package_terms_${selectedLang}`]}
              value={formData?.[`package_terms_${selectedLang}`] || ""}
              onChange={(e) => {
                handlePackageInputsChanges({ key: `package_terms_${selectedLang}`, value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: `package_terms_${selectedLang}`, value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.[`package_terms_${selectedLang}`]?.required ? "danger" : ""}
              errors={errors?.[`package_terms_${selectedLang}`]}
            />
          </div>

        </div>

        {/* Hotel Service Transalte */}
        {servicesItems?.some(ser => ser?.id === 'builder_hotel') &&
          // packageData?.builder_hotel?.map((hotel, index) =>
          prepareTranslateBuilderHotel(packageData?.items)?.map((hotel, index) =>
            <div className='product-itinerary-container mb-3'>
              <div className='product-itinerary-title'>
                <h3 className='collapse-title'>
                  {Service_Builder?.hotel} {index + 1}
                </h3>
                <div>

                  <button className='btn shadow-none' type='button' onClick={() => toggleCollapse(index, 'hotel')}>
                    {(isOpenIndex === index) && (acticeItem === 'hotel') ?
                      <i className="fas fa-chevron-up" />
                      :
                      <i className="fas fa-chevron-down" />}
                  </button>
                </div>


              </div>
              <Collapse isOpen={(isOpenIndex === index) && (acticeItem === 'hotel')}>
                <HotelTranslate
                  data={hotel}
                  setData={(recivedData) => {
                    // console.log(recivedData)
                    handlePackageInputsChanges({ key: 'builder_hotel', value: recivedData }, index)
                  }}
                  selectedLang={selectedLang}
                />
              </Collapse>
            </div>
          )}

        {/* Flight Service Transalte */}
        {servicesItems?.some(ser => ser?.id === 'builder_flight') &&
          prepareTranslateBuilderFlight(packageData?.items)?.map((flight, index) =>
            <div className='product-itinerary-container mb-3'>
              <div className='product-itinerary-title'>
                <h3 className='collapse-title'>
                  {Service_Builder?.flight} {index + 1}
                </h3>
                <div>

                  <button className='btn shadow-none' type='button' onClick={() => toggleCollapse(index, "flight")}>
                    {(isOpenIndex === index) && (acticeItem === 'flight') ?
                      <i className="fas fa-chevron-up" />
                      :
                      <i className="fas fa-chevron-down" />}
                  </button>
                </div>


              </div>
              <Collapse isOpen={(isOpenIndex === index) && (acticeItem === 'flight')}>
                <FlightTranslate
                  data={flight}
                  setData={(recivedData) => {
                    // console.log(recivedData)
                    handlePackageInputsChanges({ key: 'builder_flight', value: recivedData }, index)
                  }}
                  selectedLang={selectedLang}
                />
              </Collapse>
            </div>
          )}

        {/* Transfer Service Transalte */}
        {servicesItems?.some(ser => ser?.id === 'builder_transfer') &&
          prepareTranslateBuilderTransfer(packageData?.items)?.map((transfer, index) =>
            <div className='product-itinerary-container mb-3'>
              <div className='product-itinerary-title'>
                <h3 className='collapse-title'>
                  {Service_Builder?.transfer} {index + 1}
                </h3>
                <div>

                  <button className='btn shadow-none' type='button' onClick={() => toggleCollapse(index, "transfer")}>
                    {(isOpenIndex === index) && (acticeItem === 'transfer') ?
                      <i className="fas fa-chevron-up" />
                      :
                      <i className="fas fa-chevron-down" />}
                  </button>
                </div>


              </div>
              <Collapse isOpen={(isOpenIndex === index) && (acticeItem === 'transfer')}>
                <TransferTranslate
                  data={transfer}
                  setData={(recivedData) => {
                    // console.log(recivedData)
                    handlePackageInputsChanges({ key: 'builder_transfer', value: recivedData }, index)
                  }}
                  selectedLang={selectedLang}
                  isSubmitPackage={isSubmitForm}
                />
              </Collapse>
            </div>
          )}

        {/* Attraction Service Transalte */}
        {servicesItems?.some(ser => ser?.id === 'builder_tour') &&
          prepareTranslateBuilderAttraction(packageData?.items)?.map((attraction, index) =>
            <div className='product-itinerary-container mb-3'>
              <div className='product-itinerary-title'>
                <h3 className='collapse-title'>
                  {Service_Builder?.attraction} {index + 1}
                </h3>
                <div>

                  <button className='btn shadow-none' type='button' onClick={() => toggleCollapse(index, "attraction")}>
                    {(isOpenIndex === index) && (acticeItem === 'attraction') ?
                      <i className="fas fa-chevron-up" />
                      :
                      <i className="fas fa-chevron-down" />}
                  </button>
                </div>


              </div>
              <Collapse isOpen={(isOpenIndex === index) && (acticeItem === 'attraction')}>
                <AttractionTranslate
                  data={attraction}
                  setData={(recivedData) => {
                    // console.log(recivedData)
                    handlePackageInputsChanges({ key: 'builder_attraction', value: recivedData }, index)
                  }}
                  selectedLang={selectedLang}
                />
              </Collapse>
            </div>
          )}

        {/* Save Button */}
        <div className="col-12 d-flex justify-content-end gap-10 mt-5">
          <button
            type='button'
            onClick={() => history.push('/website/service-builder')}
            className="submit-addNew-btn"
          >
            <span className="btn-overlay"></span>
            <span className="text d-flex align-items-center gap-10 justify-content-center">
              {Service_Builder?.discard}
            </span>
          </button>

          <button type="submit" className="submit-btn ">
            <span className="btn-overlay"></span>
            <span className="text d-flex align-items-center gap-10 justify-content-center">
              {Service_Builder?.save_changes}
            </span>
          </button>
        </div>

      </form >
    </div >
  )
}

export default PackageTranslate