import { useEffect, useState } from 'react';
// React-Router-Dom
import { useHistory, useParams } from 'react-router-dom';
// Translations
import Locale from 'translations'
// Lodash
import _ from "lodash";
// Assets
import { ReactComponent as MagicIcon } from 'assets/images/serviceBuilder/magic-translate.svg'
// Context
// import { useSBSState } from 'context/global';
// Components
import TextField from 'components/Form/TextField/TextField';
import MultiTagsTextField from './MultiTagsTextField';
import useDidMountEffect from 'hooks/useDidMountEffect ';
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
// Custom Hooks
import useSharedData from '../constant/useSharedData';
// Helpler
import validate, { isFormValid } from 'helpers/validate';
// Assets
import { ReactComponent as CloseIcon } from "assets/images/serviceBuilder/x.svg";
// Services
import { addServiceTranslate } from 'services/serviveBuilder';
import { removeHTMLTags } from 'modules/WebBuilder-V2/helpers/removeHtmlTag';
// -------------------------------------------------------------------------------------------------

function AttractionTranslate({ data, setData, selectedLang }) {

  // *** hooks
  const { Service_Builder } = Locale

  const { handleAutoTranslate } = useSharedData()

  const history = useHistory()

  const { serviceName } = useParams()

  // *** States
  const [errors, setErrors] = useState({});

  const [inclusions, setInclusions] = useState('')

  const [exclusions, setExclusions] = useState('')

  const [attractionData, setAttractionData] = useState(data);

  const [formData, setFormData] = useState(data);

  const requiredInputs = [
    `name_${selectedLang}`,
    `attraction_address_${selectedLang}`,
    `inclusions_${selectedLang}`,
    `exclusions_${selectedLang}`,
    `attraction_description_${selectedLang}`,
    `attraction_policy_${selectedLang}`,
    `attraction_terms_${selectedLang}`,
  ]

  // *** Fuctions
  const handleAttractionInputsChanges = ({ key, value }) => {
    let formDataClone = _.cloneDeep(formData)
    formDataClone[`${key}`] = value
    setFormData({ ...formDataClone })
  }

  const handleAddInclusion = () => {
    let formDataClone = _.cloneDeep(formData)
    if (inclusions !== '') {
      setFormData({
        ...formDataClone,
        [`inclusions_${selectedLang}`]: formDataClone?.[`inclusions_${selectedLang}`] ?
          [...formDataClone?.[`inclusions_${selectedLang}`], inclusions]
          : [inclusions]
      })
      setInclusions('')
    }
  }

  const handleRemoveInclusion = (index) => {
    let formDataClone = _.cloneDeep(formData)
    formDataClone?.[`inclusions_${selectedLang}`]?.splice(index, 1)
    setFormData({ ...formDataClone })
  }

  const handleAddExclusions = () => {
    let formDataClone = _.cloneDeep(formData)
    if (exclusions !== '') {
      setFormData({
        ...formDataClone,
        [`exclusions_${selectedLang}`]: formDataClone?.[`exclusions_${selectedLang}`] ?
          [...formDataClone?.[`exclusions_${selectedLang}`], exclusions]
          : [exclusions]
      })
      setExclusions('')
    }
  }

  const handleRemoveExclusions = (index) => {
    let formDataClone = _.cloneDeep(formData)
    formDataClone?.[`exclusions_${selectedLang}`]?.splice(index, 1)
    setFormData({ ...formDataClone })
  }

  function checkFormErrors() {
    let submitError = {};
    requiredInputs.forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: formData[key] },
          { required: true }
        )
      }
    })
    setErrors(() => submitError);
    return submitError;
  }

  async function submit(e) {
    e?.preventDefault();
    let formErrors = checkFormErrors();
    if (!isFormValid(formErrors)) {
      return
    }
    let requestBody = {
      name: { [selectedLang]: formData?.[`name_${selectedLang}`] },
      address: { [selectedLang]: formData?.[`attraction_address_${selectedLang}`] },
      inclusion: { [selectedLang]: formData?.[`inclusions_${selectedLang}`] },
      exclusion: { [selectedLang]: formData?.[`exclusions_${selectedLang}`] },
      policy: { [selectedLang]: formData?.[`attraction_policy_${selectedLang}`] },
      description: { [selectedLang]: formData?.[`attraction_description_${selectedLang}`] },
      terms: { [selectedLang]: formData?.[`attraction_terms_${selectedLang}`] },
    }
    // console.log(requestBody)
    const response = await addServiceTranslate(attractionData?.id, 'attractions', requestBody);
    if ((response?.status === 200 || response?.status === 201)) {
      history.push('/website/service-builder')
    }
  }

  const showFormData = () => {
    const transformedData = {
      [`name_${selectedLang}`]: data?.name?.[selectedLang],
      [`attraction_address_${selectedLang}`]: data?.address?.[selectedLang],
      [`inclusions_${selectedLang}`]: data?.inclusion?.[selectedLang],
      [`exclusions_${selectedLang}`]: data?.exclusion?.[selectedLang],
      [`attraction_policy_${selectedLang}`]: removeHTMLTags(data?.policy?.[selectedLang]),
      [`attraction_description_${selectedLang}`]: removeHTMLTags(data?.description?.[selectedLang]),
      [`attraction_terms_${selectedLang}`]: removeHTMLTags(data?.terms?.[selectedLang]),
    }
    setFormData(transformedData)
  }

  useDidMountEffect(() => {
    if (formData?.[`inclusions_${selectedLang}`]?.length) {
      setErrors({
        ...errors,
        ...validate(
          { name: `inclusions_${selectedLang}`, value: formData?.[`inclusions_${selectedLang}`]?.length },
          { required: true }
        ),
      });
    }
  }, [formData?.[`inclusions_${selectedLang}`]])

  useDidMountEffect(() => {
    if (formData?.[`exclusions_${selectedLang}`]?.length) {
      setErrors({
        ...errors,
        ...validate(
          { name: `formData${selectedLang}`, value: formData?.[`exclusions_${selectedLang}`]?.length },
          { required: true }
        ),
      });
    }
  }, [formData?.[`exclusions_${selectedLang}`]])

  useDidMountEffect(() => {
    setData({ ...attractionData, ...formData })
  }, [serviceName === 'attraction' ? null : formData])

  useEffect(() => {
    setAttractionData(data)
    showFormData()
  }, [serviceName === 'attraction' ? data : null, selectedLang])

  // console.log(attractionData)

  // ---------- JSX Code ----------
  return (
    <div className='service-builder-holder service-translate-holder '>

      <h3 className='title mb-4'>{Service_Builder?.attraction}</h3>

      <form onSubmit={submit}>

        {/* Attraction Service Name */}
        <div className='row align-items-center mb-3'>

          <div className='col-11 col-md-5'>
            <p className='mb-1'>{Service_Builder?.service_name} </p>
            <p className='service-info'>{attractionData?.name?.['en']} </p>
          </div>

          <div className='col-1 d-flex pointer-event justify-content-center mt-4'>
            <MagicIcon
              onClick={() =>
                handleAutoTranslate({ targetLanguage: selectedLang, text: attractionData?.name?.['en'] })
                  .then(result => handleAttractionInputsChanges({ key: `name_${selectedLang}`, value: result }))
              }
            />
          </div>

          <div className='col-12 col-md-6 mt-4'>
            <TextField
              type="text"
              hasLabel={false}
              placeholder={Service_Builder?.service_name}
              name={`name_${selectedLang}`}
              id={`name_${selectedLang}`}
              // value={attractionData?.[`name_${selectedLang}`]}
              value={formData?.[`name_${selectedLang}`] || ''}
              onChange={(e) => {
                handleAttractionInputsChanges({ key: `name_${selectedLang}`, value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: `name_${selectedLang}`, value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.[`name_${selectedLang}`]?.required ? "danger" : ""}
              errors={errors?.[`name_${selectedLang}`]}
            />
          </div>

        </div>

        {/* Attraction Service Address */}
        <div className='row align-items-center mb-3'>

          <div className='col-11 col-md-5'>
            <p className='mb-1'> {Service_Builder?.service_address} </p>
            <p className='service-info'> {attractionData?.address?.['en']}</p>
          </div>

          <div className='col-1 d-flex pointer-event justify-content-center mt-4'>
            <MagicIcon
              onClick={() =>
                handleAutoTranslate({ targetLanguage: selectedLang, text: attractionData?.address?.['en'] })
                  .then(result => handleAttractionInputsChanges({ key: `attraction_address_${selectedLang}`, value: result }))
              }
            />
          </div>

          <div className='col-12 col-md-6 mt-4'>
            <TextField
              type="text"
              hasLabel={false}
              placeholder={Service_Builder?.service_address}
              name={`attraction_address_${selectedLang}`}
              id={`attraction_address_${selectedLang}`}
              // value={attractionData?.[`attraction_address_${selectedLang}`]}
              value={formData?.[`attraction_address_${selectedLang}`] || ''}
              onChange={(e) => {
                handleAttractionInputsChanges({ key: `attraction_address_${selectedLang}`, value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: `attraction_address_${selectedLang}`, value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.[`attraction_address_${selectedLang}`]?.required ? "danger" : ""}
              errors={errors?.[`attraction_address_${selectedLang}`]}
            />
          </div>

        </div>

        {/* Attraction Service Inclusions */}
        <div className='row align-items-start  mb-3'>

          <div className='col-11 col-md-5'>
            <p className='mb-1'> {Service_Builder?.inclusions} </p>
            <div className='d-flex flex-wrap align-items-center'>
              {attractionData?.inclusion?.['en']?.map((item) =>
                <p className='pointer-event tag-v2 mx-1 my-1'>
                  {item}
                </p>
              )}
            </div>
          </div>

          <div className='col-1 d-flex pointer-event justify-content-center' />

          <div className='col-12 col-md-6 mt-4'>
            <MultiTagsTextField
              hasLabel={true}
              type="text"
              label={Service_Builder?.inclusions}
              placeholder={Service_Builder?.wifi_free}
              name={`inclusions_${selectedLang}`}
              id={`inclusions_${selectedLang}`}
              value={inclusions}
              onChange={(e) => {
                setInclusions(e.target.value)
              }}
              onClickAddButton={handleAddInclusion}
              // onEnter={(e) => {
              //   if (e.key === "Enter")
              //     handleAddInclusion();
              // }}
              color={errors?.[`inclusions_${selectedLang}`]?.required ? "danger" : ""}
              errors={errors?.[`inclusions_${selectedLang}`]}
            />
            <div className='d-flex flex-wrap mt-2 '>
              {formData?.[`inclusions_${selectedLang}`]?.map((inclusion, index) =>
                <div className='tag-v2 mx-1 my-1' >
                  {inclusion}
                  <span
                    className='pointer-event font-bold'
                    onClick={() => handleRemoveInclusion(index)}
                  >
                    <CloseIcon />
                  </span>
                </div>
              )}
            </div>
          </div>

        </div>

        {/* Attraction Service Exclusions */}
        <div className='row align-items-start  mb-3'>

          <div className='col-11 col-md-5'>
            <p className='mb-1'> {Service_Builder?.exclusions} </p>
            <div className='d-flex flex-wrap align-items-center'>
              {attractionData?.exclusion?.['en']?.map((item) =>
                <p className='pointer-event tag-v2 mx-1 my-1'>
                  {item}
                </p>
              )}
            </div>
          </div>

          <div className='col-1 d-flex pointer-event justify-content-center' />

          <div className='col-12 col-md-6 mt-4'>
            <MultiTagsTextField
              hasLabel={true}
              type="text"
              label={Service_Builder?.exclusions}
              placeholder={Service_Builder?.wifi_free}
              name={`exclusions_${selectedLang}`}
              id={`exclusions_${selectedLang}`}
              value={exclusions}
              onChange={(e) => {
                setExclusions(e.target.value)
              }}
              onClickAddButton={handleAddExclusions}
              // onEnter={(e) => {
              //   if (e.key === "Enter")
              //     handleAddExclusions();
              // }}
              color={errors?.[`exclusions_${selectedLang}`]?.required ? "danger" : ""}
              errors={errors?.[`exclusions_${selectedLang}`]}
            />
            <div className='d-flex flex-wrap mt-2 '>
              {formData?.[`exclusions_${selectedLang}`]?.map((exclusion, index) =>
                <div className='tag-v2 mx-1 my-1' >
                  {exclusion}
                  <span
                    className='pointer-event font-bold'
                    onClick={() => handleRemoveExclusions(index)}
                  >
                    <CloseIcon />
                  </span>
                </div>
              )}
            </div>
          </div>

        </div>

        {/* Attraction Description*/}
        <div className='row align-items-start mb-3'>

          <div className='col-11 col-md-5'>
            <p className='mb-1'> {Service_Builder?.description} </p>
            <p className='service-info'> {removeHTMLTags(attractionData?.description?.['en'])}</p>
          </div>

          <div className='col-1 d-flex pointer-event justify-content-center mt-4 '>
            <MagicIcon
              onClick={() =>
                handleAutoTranslate({ targetLanguage: selectedLang, text: attractionData?.description?.['en'] })
                  .then(result => handleAttractionInputsChanges({ key: `attraction_description_${selectedLang}`, value: result }))
              }
            />
          </div>

          <div className='col-12 col-md-6 mt-3'>
            <TextAreaField
              height={'80px'}
              type="text"
              hasLabel={false}
              placeholder={Service_Builder?.description}
              name={`attraction_description_${selectedLang}`}
              id={`attraction_description_${selectedLang}`}
              // value={attractionData?.[`attraction_description_${selectedLang}`]}
              value={formData?.[`attraction_description_${selectedLang}`] || ''}
              onChange={(e) => {
                handleAttractionInputsChanges({ key: `attraction_description_${selectedLang}`, value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: `attraction_description_${selectedLang}`, value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.[`attraction_description_${selectedLang}`]?.required ? "danger" : ""}
              errors={errors?.[`attraction_description_${selectedLang}`]}
            />
          </div>

        </div>

        {/* Attraction Policy*/}
        <div className='row align-items-start mb-3'>

          <div className='col-11 col-md-5'>
            <p className='mb-1'> {Service_Builder?.policy} </p>
            <p className='service-info'> {removeHTMLTags(attractionData?.policy?.['en'])}</p>
          </div>

          <div className='col-1 d-flex pointer-event justify-content-center mt-4 '>
            <MagicIcon
              onClick={() =>
                handleAutoTranslate({ targetLanguage: selectedLang, text: attractionData?.policy?.['en'] })
                  .then(result => handleAttractionInputsChanges({ key: `attraction_policy_${selectedLang}`, value: result }))
              }
            />
          </div>

          <div className='col-12 col-md-6 mt-3'>
            <TextAreaField
              height={'80px'}
              type="text"
              hasLabel={false}
              placeholder={Service_Builder?.policy}
              name={`attraction_policy_${selectedLang}`}
              id={`attraction_policy_${selectedLang}`}
              // value={attractionData?.[`attraction_policy_${selectedLang}`]}
              value={formData?.[`attraction_policy_${selectedLang}`] || ''}
              onChange={(e) => {
                handleAttractionInputsChanges({ key: `attraction_policy_${selectedLang}`, value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: `attraction_policy_${selectedLang}`, value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.[`attraction_policy_${selectedLang}`]?.required ? "danger" : ""}
              errors={errors?.[`attraction_policy_${selectedLang}`]}
            />
          </div>

        </div>

        {/* Attraction Terms And Condition*/}
        {serviceName === 'attraction' &&
          <div className='row align-items-start mb-3'>

            <div className='col-11 col-md-5'>
              <p className='mb-1'> {Service_Builder?.termsAndConditions} </p>
              <p className='service-info'> {removeHTMLTags(attractionData?.terms?.['en'])}</p>
            </div>

            <div className='col-1 d-flex pointer-event justify-content-center mt-4 '>
              <MagicIcon
                onClick={() =>
                  handleAutoTranslate({ targetLanguage: selectedLang, text: attractionData?.terms?.['en'] })
                    .then(result => handleAttractionInputsChanges({ key: `attraction_terms_${selectedLang}`, value: result }))
                }
              />
            </div>

            <div className='col-12 col-md-6 mt-3'>
              <TextAreaField
                height={'80px'}
                type="text"
                hasLabel={false}
                placeholder={Service_Builder?.termsAndConditions}
                name={`attraction_terms_${selectedLang}`}
                id={`attraction_terms_${selectedLang}`}
                // value={attractionData?.[`attraction_terms_${selectedLang}`]}
                value={formData?.[`attraction_terms_${selectedLang}`] || ''}
                onChange={(e) => {
                  handleAttractionInputsChanges({ key: `attraction_terms_${selectedLang}`, value: e?.target?.value })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: `attraction_terms_${selectedLang}`, value: e?.target?.value },
                      { required: true }
                    ),
                  });
                }}
                color={errors?.[`attraction_terms_${selectedLang}`]?.required ? "danger" : ""}
                errors={errors?.[`attraction_terms_${selectedLang}`]}
              />
            </div>

          </div>
        }

        {/* Save Button */}
        {
          serviceName === 'attraction' &&
          <div className="col-12 d-flex justify-content-end gap-10 mt-3">
            <button
              type='button'
              onClick={() => history.push('/website/service-builder')}
              className="submit-addNew-btn"
            >
              <span className="btn-overlay"></span>
              <span className="text d-flex align-items-center gap-10 justify-content-center">
                {Service_Builder?.discard}
              </span>
            </button>

            <button type="submit" className="submit-btn ">
              <span className="btn-overlay"></span>
              <span className="text d-flex align-items-center gap-10 justify-content-center">
                {Service_Builder?.save_changes}
              </span>
            </button>
          </div>
        }

      </form>

    </div>
  )
}

export default AttractionTranslate