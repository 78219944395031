// Translation
import Locale from 'translations';
// Shared Components
import EditableParagraph from 'modules/WebBuilder/shared/EditableParagraph';
// Components
import TextField from 'components/Form/TextField/TextField';
// Icons
import { ReactComponent as DeleteIcon } from "assets/images/webBuilder/red-trash.svg";
import { ReactComponent as DraghIcon } from "assets/images/hotelAccommodation/drag.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location2.svg";
import { ReactComponent as CallIcon } from "assets/images/webBuilder/call2.svg";
import { ReactComponent as WhatsAppIcon } from "assets/images/webBuilder/whatsapp2.svg";
import { ReactComponent as SMSIcon } from "assets/images/webBuilder/sms2.svg";
// Context
import { useWebBuilderState } from 'context/webBuilder';
// ---------------------------------------------------------------------------------------------


export default function BranchForm({ branch, handleUpdateBranchInfo, handleRemoveBranch }) {

  // Hooks
  const { selectedLanguage } = useWebBuilderState();
  const { webBuilder } = Locale;

  const isMainBranch = branch?.id?.includes('main')

  // -------- JSX Code -------
  return (
    <div key={branch?.id} className='wb-page-contact-us-form' style={{ width: '100%' }}>

      <h2 className='main-title mb-1'>{webBuilder?.checkOurLocation}</h2>

      <p className='sub-title mb-3'>{webBuilder?.contactInformation}</p>

      <form className="contact-us-form">

        <div className='d-flex border-1 border-bottom  align-items-start w-100 pb-2'>
          <DraghIcon className="drag-icon" />
          <EditableParagraph
            className="form-title flex-grow-1"
            contentEditable={true}
            onInput={(value) =>
              handleUpdateBranchInfo(
                {
                  branchId: branch?.id,
                  infoKey: "title",
                  infoValue: value,
                  language: selectedLanguage
                }
              )
            }
            text={branch?.title?.[selectedLanguage]}
          />
          {!isMainBranch &&
            <span
              className='pointer-event'
              onClick={() => handleRemoveBranch(branch?.id)}
            >
              <DeleteIcon />
            </span>
          }

        </div>

        {/* address */}
        <div className='d-flex align-items-center'>
          <span className='icon-wrapper'>
            <LocationIcon />
          </span>
          <div className="contact-us-fields w-100">
            <TextField
              label={webBuilder.address}
              placeholder={webBuilder.enterAddress}
              value={branch?.address?.[selectedLanguage]}
              onChange={(e) => handleUpdateBranchInfo(
                {
                  branchId: branch?.id,
                  infoKey: "address",
                  infoValue: e.target.value,
                  language: selectedLanguage
                }
              )}
            />
          </div>
        </div>

        {/* phone  */}
        <div className='d-flex align-items-center'>
          <span className='icon-wrapper'>
            <CallIcon />
          </span>
          <div className="contact-us-fields w-100">
            <TextField
              label={webBuilder.phone}
              type="number"
              id="form-phone"
              name="form-phone"
              placeholder={webBuilder.enterPhoneNo}
              value={branch?.phone}
              onChange={(e) => handleUpdateBranchInfo(
                {
                  branchId: branch?.id,
                  infoKey: "phone",
                  infoValue: e.target.value,
                }
              )}
            />
          </div>
        </div>

        {/* whats app */}
        <div className='d-flex align-items-center'>
          <span className='icon-wrapper'>
            <WhatsAppIcon />
          </span>
          <div className="contact-us-fields w-100">
            <TextField
              label={webBuilder.whatsApp}
              placeholder={webBuilder.enterWhatsAppNo}
              type="number"
              value={branch?.whatsApp}
              onChange={(e) => handleUpdateBranchInfo(
                {
                  branchId: branch?.id,
                  infoKey: "whatsApp",
                  infoValue: e.target.value,
                }
              )}
            />
          </div>
        </div>

        {/* Email */}
        <div className='d-flex align-items-center'>
          <span className='icon-wrapper'>
            <SMSIcon />
          </span>
          <div className="contact-us-fields w-100">
            <TextField
              label={webBuilder.email}
              placeholder={webBuilder.enterEmailAddress}
              type="email"
              value={branch?.email}
              onChange={(e) => handleUpdateBranchInfo(
                {
                  branchId: branch?.id,
                  infoKey: "email",
                  infoValue: e.target.value,
                }
              )}
            />
          </div>
        </div>

      </form>

    </div>
  )
}

