import {  useState } from 'react';
// Translations
import Locale from 'translations';
// React-quill
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css"; // Snow theme
// or
import "react-quill/dist/quill.bubble.css"; // Bubble theme
// -------------------------------------------------------------------------------
export default function EditorField(props) {

  const { commons } = Locale;

  const [foucs, setFoucs] = useState(false);
  
  const generateErrorsMsgs = (errors) => {
    let message = [];
    if (errors) {
      for (const [k, v] of Object.entries(errors)) {
        if (k === "required" && v) {
          message.push(commons.isRequired);
        } else if (k === "min" && v) {
          message.push(` should be at least ${props.min} chars`);
        } else if (k === "maxNumber" && v) {
          message.push(` should not exceed ${props.max}`);
        } else if (k === "minNumber" && v) {
          message.push(` should be at least ${props.min}`);
        } else if (k === "email" && v) {
          message.push(commons.shouldBeValid);
        } else if (k === "phone" && v) {
          message.push(commons.shouldBeValid);
        } else if (k === "number" && v) {
          message.push(commons.shouldBeNumber);
        }
      }
      if (message.length === 1) {
        if (props.label) {
          return `${props.label} ${message[0]}`;
        } else {
          return `${props.placeholder} ${message[0]}`;
        }
      } else if (message.length > 1) {
        return `${props.label} ${message.join(" & ")}`;
      } else {
        return;
      }
    }
  };

  // const modules = {
  //   toolbar: [
  //     ["bold", "italic"], // Enable Bold & Italic
  //     [{ header: [1, 2, false] }], // Heading options
  //     [{ list: "ordered" }, { list: "bullet" }], // Lists
  //     ["clean"], // Clear formatting button
  //   ],
  // };

  // -------- JSX Code -------
  return (
    <div>
      {props?.hasLabel &&
        <label
          className={`control-field__label ${props.color ? " control-field__label--" + props.color : ""
            } ${foucs ? "custom-select-text" : ""}`}
        >
          {props.label}
        </label>
      }
      <ReactQuill
        className={props?.color ? `${props?.className} error` : foucs ? `${props?.className} focus` : props?.className}
        // modules={modules} // Apply toolbar settings
        style={{ minHeight: "150px" }}
        placeholder={props?.placeholder}
        value={props?.value}
        onChange={props?.onChange}
        onFocus={() => {
          setFoucs(true);
        }}
        onBlur={() => {
          if (props.onBlur !== undefined) {
            props.onBlur();
          }
          setFoucs(false);
        }}
        theme="snow"
      />
      <small
        className={`control-field__feedback control-field__feedback--${props.color
          } ${props.color ? "d-block" : null}  error-message`}
      >
        {generateErrorsMsgs(props.errors)}
      </small>

      <small
        className={`control-field__feedback control-field__feedback--${props.color
          } ${props.isInvalid ? "d-block" : "d-none"}`}
      >
        {props.feedbackMessage}
      </small>

    </div>
  )
}

