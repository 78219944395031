import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import validate, { isFormValid } from "helpers/validate";
import Locale from 'translations';
import { useSBSState } from 'context/global';
import { ReactComponent as UpgradeSubscription } from "assets/images/upgrade-subscription.svg";

import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
import { publishAllTrips } from 'services/tripPlanner';
import moment from 'moment';
import { store } from 'react-notifications-component';



// const AllOption = { id: "all", name: "All" };

export default function PublishAllTripsModal({ toggleShareModel, shareModelOpen, product_type, productData, tripsList, packageId }) {
  // hooks
  const { productsBuilder } = Locale;
  const { share_b2b, subscriptionPlan } = useSBSState();
  // states
  // const [companiesOptions, setCompaniesOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const [publishData, setPublishData] = useState({
    date_from: "",
    date_to: "",
    booking_type: "manual",
    terms: "",
    documents: "",
    share_type: "online",
    b2c_share: false,
    b2b_share: false,
    public_share: false,
    countries: [],
    companies: [],
    attachment: [],
    photo: null,
    trips: []
  });

  // varibales
  const checkShareFor =
    publishData["b2c_share"] ||
    publishData["b2b_share"] ||
    publishData["public_share"];

  const sharing_on_b2b_subscription =
    !subscriptionPlan?.includes("sharing_on_b2b");
  const sharing_on_b2c_subscription =
    !subscriptionPlan?.includes("sharing_on_b2c");

  const customPackageType = product_type
    ? product_type?.id === 5
    : productData?.product_type?.id === 5;
  const serviceType = product_type
    ? product_type?.id === 6
    : productData?.product_type?.id === 6;

  // const isBreakDown =
  //   is_break_down || productData?.product_price?.product_price_type?.id === 0;




  function checkFormErrors() {
    let SubmitError = {};
    let dataKeys = ["date_from", "date_to", "booking_type", "share_type", "share_for", "trips"];

    if (publishData.b2b_share) {
      dataKeys = [...dataKeys];
    }

    dataKeys.forEach((key) => {
      SubmitError = {
        ...SubmitError,
        ...validate(
          {
            name: key,
            value:
              key === "share_for"
                ? publishData.share_type === "offline"
                  ? "false"
                  : checkShareFor
                : publishData[key],
          },
          { required: true }
        ),
      };
    });

    setErrors(() => SubmitError);
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }





  async function handlepublishAllTrips() {
    const { date_from, date_to, booking_type, terms, documents, share_type, b2c_share, b2b_share, public_share, companies, countries, trips } = publishData;
    const for_b2c = b2c_share ? 1 : 0;
    const for_b2b = b2b_share ? 1 : 0;
    const for_public = public_share ? 1 : 0;
    let data = {
      share_from: moment(date_from).format("YYYY-MM-DD"),
      share_to: moment(date_to).format("YYYY-MM-DD"),
      booking_type: booking_type,
      share_terms: terms,
      share_required_documents: documents,
      share_type: share_type,
      for_b2c,
      for_b2b,
      for_public,
      countries: null,
      companies: null,
      attachments: null,
      photo: null,
      trips
    };

    if (for_b2b) {
      data = {
        ...data,
      };
    }

    const publishRes = await publishAllTrips(packageId, data);
    if (publishRes?.status >= 200 && publishRes?.status < 300) {
      store.addNotification({
        message: productsBuilder.packagePublishSuccessfully,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      toggleShareModel();

    }
  }

  useEffect(() => {
    if (isFormValid(errors)) {
      handlepublishAllTrips();
    }
  }, [isErrorLoaded]);


  return (
    <Modal isOpen={shareModelOpen} size="lg">
      <ModalHeader toggle={toggleShareModel}>
        {productsBuilder.SharingForm}
      </ModalHeader>
      <ModalBody>
        <div className="col-10 mx-auto share-package-modal">
          <div className="row">
            {/* share from date */}
            <div className="col-6">
              <DatePickerField
                label={productsBuilder.shareForm}
                placeholder="DD/MM/YYYY"
                date={publishData.date_from}
                onDateChange={(date) => {
                  setPublishData({ ...publishData, date_from: date });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "date_from", value: date },
                      { required: true }
                    ),
                  });
                }}
                errors={errors?.date_from}
                color={errors?.date_from?.required ? "danger" : ""}
              />
            </div>

            {/* share to data */}
            <div className="col-6">
              <DatePickerField
                label={productsBuilder.shareTo}
                placeholder="DD/MM/YYYY"
                date={publishData.date_to}
                onDateChange={(date) => {
                  setPublishData({ ...publishData, date_to: date });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "date_to", value: date },
                      { required: true }
                    ),
                  });
                }}
                errors={errors?.date_to}
                color={errors?.date_to?.required ? "danger" : ""}
              />
            </div>

            {/* booking type */}
            <div className="col-12 mt-3">
              <p>{productsBuilder.reservationType}</p>
              <div className="col-12 row align-items-center mt-1">
                {/* manual reservation */}
                <div className="col-md-4 col-6 d-flex align-items-center">
                  <input
                    class="mx-2 radio-input"
                    type="radio"
                    name="manual"
                    id="manual"
                    value="manual"
                    checked={publishData.booking_type === "manual"}
                    onChange={(e) => {
                      setPublishData({
                        ...publishData,
                        booking_type: e.target.value,
                      });
                    }}
                  />
                  <label className="form-check-label" for="manual">
                    {productsBuilder.manualReservation}
                  </label>
                </div>
                {/* auto reservation  */}
                <div className="col-md-4 col-6 d-flex align-items-center">
                  <input
                    class="mx-2 radio-input"
                    type="radio"
                    name="auto"
                    id="auto"
                    value="auto"
                    checked={publishData.booking_type === "auto"}
                    onChange={(e) => {
                      setPublishData({
                        ...publishData,
                        booking_type: e.target.value,
                      });
                    }}
                  />
                  <label className="form-check-label" for="auto">
                    {productsBuilder.autoReservation}
                  </label>
                </div>
              </div>
            </div>

            {/* sharing type */}
            <div className="col-12 mt-3">
              <p>{productsBuilder.sharingType}</p>
              <div className="col-12 row align-items-center mt-1">
                {/* Shared Online */}
                <div className="col-md-4 col-6 d-flex align-items-center">
                  <input
                    class="mx-2 radio-input"
                    type="radio"
                    name="online"
                    id="online"
                    value="online"
                    checked={publishData.share_type === "online"}
                    onChange={(e) => {
                      setPublishData({
                        ...publishData,
                        share_type: e.target.value,
                      });
                    }}
                  />
                  <label className="form-check-label" for="online">
                    {productsBuilder.shareMarketplace}
                  </label>
                </div>
                {/* Shared Offline */}
                <div className="col-md-4 col-6 d-flex align-items-center">
                  <input
                    class="mx-2 radio-input"
                    type="radio"
                    name="offline"
                    id="offline"
                    value="offline"
                    checked={publishData.share_type === "offline"}
                    onChange={(e) => {
                      setPublishData({
                        ...publishData,
                        share_type: e.target.value,
                      });
                    }}
                  />
                  <label className="form-check-label" for="offline">
                    {productsBuilder.shareReservationTeam}
                  </label>
                </div>
              </div>
            </div>

            {/* sharing for */}
            {publishData.share_type !== "offline" ? (
              <div className="col-12 mt-3">
                <p>
                  {productsBuilder.sharingFor}{" "}
                  {errors?.share_for?.required && !checkShareFor ? (
                    <small className="mx-2 text-danger">
                      you should select share for
                    </small>
                  ) : null}
                </p>
                <div className="col-12 row align-items-start mt-1">
                  {/* Shared B2C */}

                  <div
                    // className="col-md-4 col-6 d-flex align-items-start"
                    className={`col-md-4 col-6 d-flex align-items-start ${sharing_on_b2c_subscription ? "shared-disabled" : ""
                      }`}
                  >
                    <input
                      class="mx-2 radio-input"
                      type="checkbox"
                      name="B2C"
                      id="B2C"
                      checked={publishData.b2c_share}
                      onChange={(e) => {
                        setPublishData({
                          ...publishData,
                          b2c_share: e.target.checked,
                        });
                      }}
                      disabled={
                        serviceType ||
                        customPackageType ||
                        sharing_on_b2c_subscription
                      }
                    />
                    <label className="form-check-label" for="B2C">
                      B2C
                    </label>
                    {sharing_on_b2c_subscription ? (
                      <div className="upgrade">
                        <UpgradeSubscription />
                        <span>Upgrade</span>
                      </div>
                    ) : null}
                  </div>
                  {/* Shared B2B */}
                  {share_b2b !== "0" ? (
                    <div
                      className={`col-md-6 col-6 d-flex flex-column align-items-start ${sharing_on_b2b_subscription ? "shared-disabled" : ""
                        }`}
                    >
                      <div className="d-flex">
                        <input
                          class="mx-2 radio-input"
                          type="checkbox"
                          name="B2B"
                          id="B2B"
                          checked={publishData.b2b_share}
                          onChange={(e) => {
                            setPublishData({
                              ...publishData,
                              b2b_share: e.target.checked,
                            });
                          }}
                          disabled={sharing_on_b2b_subscription}
                        />
                        <label className="form-check-label" for="B2B">
                          Share on Safa-Live Market-place
                        </label>
                        {sharing_on_b2b_subscription ? (
                          <div className="upgrade">
                            <UpgradeSubscription />
                            <span>Upgrade</span>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}


            {/* trips list */}
            <div className="my-3 col-12">
              <h2 className="d-flex align-items-center">
                Trips List
                {errors?.trips?.required && publishData?.trips?.length === 0 &&
                  <small className="mx-2 text-danger">
                    you should select at least one trip
                  </small>
                }
              </h2>
              {tripsList?.filter(trip => trip?.has_price)?.map(trip => {
                return (
                  <div className="d-flex align-items-center col-12 my-2 text-capitalize" key={trip?.product_uuid}>
                    <input
                      class="radio-input"
                      type="checkbox"
                      name={trip?.product_uuid}
                      id={trip?.product_uuid}
                      checked={publishData.trips.includes(trip?.product_uuid)}
                      onChange={(e) => {
                        if (publishData.trips.find(tripuuid => tripuuid === trip?.product_uuid)) {
                          setPublishData({ ...publishData, trips: publishData.trips?.filter(tripuuid => tripuuid !== trip?.product_uuid) });
                          return
                        }
                        setPublishData({ ...publishData, trips: [...publishData.trips, trip?.product_uuid] });
                      }}
                    />
                    <label className="form-check-label mx-2" style={{ fontSize: "16px", }} for={trip?.product_uuid}>
                      {trip?.name}
                    </label>

                  </div>
                )
              })}
            </div>

            {/* terms and conditions */}
            <div className="col-12">
              <TextAreaField
                name="terms"
                id="terms"
                label={productsBuilder.termsAndConditions}
                placeholder={productsBuilder.termsAndConditions}
                rows="5"
                className="textarea"
                onChange={(e) => {
                  setPublishData({ ...publishData, terms: e.target.value });
                }}
                height={"50px"}
                errors={errors?.terms}
                color={errors?.terms?.required ? "danger" : ""}
              />
            </div>
            {/* Required Documents */}
            <div className="col-12 mt-3">
              <TextAreaField
                name="documents"
                id="documents"
                label={productsBuilder.requiredDocuments}
                placeholder={productsBuilder.requiredDocuments}
                rows="5"
                className="textarea"
                onChange={(e) => {
                  setPublishData({
                    ...publishData,
                    documents: e.target.value,
                  });

                }}
                height={"50px"}
                errors={errors?.documents}
                color={errors?.documents?.required ? "danger" : ""}
              />
            </div>
            {/* share button */}
            <div className="col-12 mt-3" onClick={checkFormErrors}>
              <button className="btn">{productsBuilder.share}</button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
