// Translation
import Locale from 'translations';
// React-Router-Dom
import { useLocation } from 'react-router-dom';
// Components
import TextField from 'components/Form/TextField/TextField'
// Icons
import ProfileIcon from "assets/images/webBuilder/profile.svg";
import SmsIcon from "assets/images/webBuilder/sms.svg";
import MobileIcon from "assets/images/webBuilder/mobile-icon.svg";
// ------------------------------------------------------------------------
export default function ContactForm() {

  // Hooks
  const location = useLocation();
  const { webBuilder } = Locale;

  const preview = !location.pathname.includes("edit");
  // --------- JSX Code ---------
  return (
    <div className='wb-page-contact-us-form'>
      <h2 className='main-title mb-1'>{webBuilder?.sendUsMessage}</h2>
      <p className='sub-title mb-3'>{webBuilder?.contactForm}</p>
      <form className="contact-us-form">
        <div className="contact-us-fields">
          <TextField
            hasLabel={false}
            placeholder={webBuilder.name}
            // value={formData.name}
            // onChange={(e) =>
            //   handleFormData({ name: "name", value: e.target.value })
            // }
            isImage
            image={ProfileIcon}
            prependImage
            required
          />
        </div>
        <div className="contact-us-fields">
          <TextField
            hasLabel={false}
            placeholder={webBuilder.email}
            type="email"
            isImage
            image={SmsIcon}
            prependImage
            required
          />
        </div>

        {/* phone  */}
        <div className="contact-us-fields">
          <TextField
            type="number"
            hasLabel={false}
            id="form-phone"
            name="form-phone"
            placeholder={webBuilder.phone}
            isImage
            image={MobileIcon}
            prependImage
            required
          />
        </div>

        <textarea
          className='textarea-field'
          rows={5}
          placeholder={webBuilder.message}
          required
        />

        <button
          type={preview ? webBuilder.submit : "button"}
          className="submit-btn"
        >
          {webBuilder.submit}
        </button>
      </form>
    </div>
  )
}

