import { useEffect, useState } from 'react';
// Translation
import Locale from 'translations';
// Componnets
import TextField from 'components/Form/TextField/TextField';
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
import UploadFile from 'components/UploadFile';
// Shared Components
import MultiTagsTextField from '../shared/MultiTagsTextField';
import DefualtPricesTaxes from '../shared/DefualtPricesTaxes';
// Services
import { addOtherService, getServicesById, updateService } from 'services/serviveBuilder';
import { useHistory, useParams } from 'react-router-dom';
import useSharedData from '../constant/useSharedData';
// Helper
import validate, { isFormValid } from 'helpers/validate';
import generateUniqueID from 'helpers/generateUniqueID';
// Assets
import { ReactComponent as CloseIcon } from "assets/images/serviceBuilder/x.svg";
// Custom Hook
import useDidMountEffect from 'hooks/useDidMountEffect ';
import { store } from 'react-notifications-component';
import EditorField from 'components/Form/EditorField/EditorField';
import { removeHTMLTags } from 'modules/WebBuilder-V2/helpers/removeHtmlTag';
// ------------------------------------------------------------------------------------------------

const ServiceBuilderOthersForm = () => {

  const initialOtherData = {
    name_en: "",
    name_ar: "",
    address: "",
    inclusions: [],
    exclusions: [],
    policy_en: "",
    policy_ar: "",
    description_en: "",
    description_ar: "",
    images: [],
    price: '',
    currency: "SAR",
    taxes: [{
      name: '',
      type: '',
      value: ''
    }]
  }
  // *** Hooks
  const { inventory, commons, Service_Builder, companyDetails } = Locale;
  const history = useHistory();
  const { status, id } = useParams();
  const { requiredOthersInputs } = useSharedData()


  // *** State
  const [otherServiceData, setOtherServiceData] = useState(initialOtherData);
  const [errors, setErrors] = useState({});
  const [showPricing, setShowPricing] = useState(false);
  const [currentInclusion, setCurrentInclusion] = useState("");
  const [currentExclusion, setCurrentExclusion] = useState("");
  const [taxesIsSelected, setTaxesIsSelected] = useState("");
  // const [selectedLang, setSelectedLang] = useState("en");


  // *** Function
  function handleOthersInputsChanges({ key, value }) {
    let otherServiceDataClone = { ...otherServiceData }
    otherServiceDataClone[`${key}`] = value
    setOtherServiceData({ ...otherServiceDataClone })

  }

  const handleAddInclusion = () => {
    if (currentInclusion.trim() && !otherServiceData?.inclusions?.includes(currentInclusion.trim())) {
      setOtherServiceData((prevData) => ({
        ...prevData,
        inclusions: [...prevData.inclusions, currentInclusion.trim()]
      }));
      setCurrentInclusion("");
    }
  };

  const handleAddExclusion = () => {
    if (currentExclusion.trim() && !otherServiceData?.exclusions?.includes(currentExclusion.trim())) {
      setOtherServiceData((prevData) => ({
        ...prevData,
        exclusions: [...prevData.exclusions, currentExclusion.trim()]
      }));
      setCurrentExclusion("");
    }
  };

  const handleRemoveInclusion = (index) => {
    setOtherServiceData((prevData) => ({
      ...prevData,
      inclusions: prevData.inclusions.filter((_, i) => i !== index)
    }));
  };

  const handleRemoveExclusion = (index) => {
    setOtherServiceData((prevData) => ({
      ...prevData,
      exclusions: prevData.exclusions.filter((_, i) => i !== index)
    }));
  };

  const handleInclusionChange = (value) => {
    setCurrentInclusion(value);
  };

  const handleExclusionChange = (value) => {
    setCurrentExclusion(value);
  };

  const checkFormErrors = () => {
    let submitError = {};
    requiredOthersInputs?.forEach((key) => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: otherServiceData[key] },
          { required: true }
        )
      }
    });
    setErrors(submitError);
    return submitError;
  };

  const fetchOtherServiceData = async () => {
    const response = await getServicesById(id)
    if (response?.status === 200) {
      setOtherServiceData({
        serviceId: response?.data?.data?.service.id,
        name_en: response?.data?.data?.service?.name?.en,
        address: response?.data?.data?.service?.address?.en,
        inclusions: response?.data?.data?.service?.inclusion?.en,
        exclusions: response?.data?.data?.service?.exclusion?.en,
        policy_en: response?.data?.data?.service?.policy?.en,
        description_en: response?.data?.data?.service?.description?.en,
        terms_en: response?.data?.data?.service?.terms?.en,
        images: response?.data?.data?.service?.images,
        currency: response?.data?.data?.service?.currency,
        price: response?.data?.data?.service?.price,
        taxes: response?.data?.data?.service?.taxes || []
      })
      setShowPricing(response?.data?.data?.service?.price ? true : false)
    }
    return response
  }

  const submit = async (e, submitType) => {
    e?.preventDefault();
    let formErrors = checkFormErrors();
    if (!isFormValid(formErrors)) {
      return;
    }
    let requestBody = {
      name: { en: otherServiceData?.name_en },
      address: { en: otherServiceData?.address },
      inclusion: { en: otherServiceData?.inclusions },
      exclusion: { en: otherServiceData?.exclusions },
      policy: { en: otherServiceData?.policy_en },
      description: { en: otherServiceData?.description_en },
      terms: { en: otherServiceData?.terms_en },
      images: otherServiceData?.images,
      price: showPricing ? (otherServiceData?.price || null) : null,
      currency: otherServiceData?.currency,
      // taxes: isPayloadValid(otherServiceData?.taxes?.[0]) ? otherServiceData?.taxes : []
      taxes: taxesIsSelected ? otherServiceData?.taxes : []
    }

    // try {
    //   if (status === 'edit') {
    //     await updateService(otherServiceData?.serviceId, "others", requestBody)
    //     history.push('/website/service-builder');
    //   } else {
    //     await addOtherService(requestBody);
    //     history.push('/website/service-builder');
    //   }
    // } catch (error) {
    //   console.error("Error submitting form:", error);
    // }
    if (status === 'add') {
      const response = await addOtherService(requestBody);
      if ((response?.status === 200 || response?.status === 201) && submitType === 'submit-add-new') {
        store.addNotification({
          message: Service_Builder?.ServiceAddedSuccessfully,
          title: Service_Builder?.Success,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
        setOtherServiceData(initialOtherData)
      }
      if ((response?.status === 200 || response?.status === 201) && !submitType) {
        history.push('/website/service-builder')
      }
    } else if (status === 'edit') {
      const response = await updateService(otherServiceData?.serviceId, "others", requestBody);
      if (response?.status === 200 || response?.status === 201) {
        history.push('/website/service-builder')
      }
    }
  };

  useEffect(() => {
    if (status === 'edit' && id) {
      fetchOtherServiceData();
    }
  }, [id]);

  useDidMountEffect(() => {
    if (otherServiceData?.exclusions?.length >= 0) {
      setErrors({
        ...errors,
        ...validate(
          { name: "exclusions", value: otherServiceData?.exclusions?.length },
          { required: true }
        ),
      });
    }
  }, [otherServiceData?.exclusions])

  useDidMountEffect(() => {
    if (otherServiceData?.inclusions?.length >= 0) {
      setErrors({
        ...errors,
        ...validate(
          { name: "inclusions", value: otherServiceData?.inclusions?.length },
          { required: true }
        ),
      });
    }
  }, [otherServiceData?.inclusions])

  console.log(otherServiceData)
  // ------- JSX Code ------
  return (
    <>
      <h1 className='form-title'>
        {Service_Builder?.select_service_details}
      </h1>
      <form onSubmit={submit} className="mt-4">
        <div className="row form-holder mx-auto mb-5">

          {/* Others Service Name en */}
          <div className="col-12 col-sm-6 mt-3">
            <TextField
              type="text"
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.service_name_en}
              placeholder={Service_Builder?.service_name_en}
              name="name_en"
              id="name_en"
              value={otherServiceData?.name_en}
              onChange={(e) => {
                handleOthersInputsChanges({ key: 'name_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "name_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.name_en?.required && "danger"}
              errors={errors.name_en}
            />
          </div>

          {/* Others Service Address */}
          <div className="col-12 col-sm-6 mt-3">
            <TextField
              type="text"
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.address}
              placeholder={Service_Builder?.address}
              name="address"
              id="address"
              value={otherServiceData?.address}
              onChange={(e) => {
                handleOthersInputsChanges({ key: 'address', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "address", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.address?.required && "danger"}
              errors={errors.address}
            />
          </div>

          {/* Others Service Inclusions */}
          <div className="col-12 col-sm-6 mt-3">
            <MultiTagsTextField
              hasLabel={true}
              type="text"
              label={Service_Builder?.inclusions}
              placeholder={Service_Builder?.wifi_free}
              name="inclusions"
              id="inclusions"
              value={currentInclusion}
              onChange={(e) => handleInclusionChange(e.target.value)}
              onClickAddButton={handleAddInclusion}
              // onEnter={(e) => {
              //   if (e.key === "Enter") handleAddInclusion();
              // }}
              color={errors?.inclusions?.required && "danger"}
              errors={errors.inclusions}
            />
            <div className='d-flex flex-wrap mt-2'>
              {otherServiceData?.inclusions?.map((inclusion, index) =>
                <div key={index} className='tag mx-1'>
                  {inclusion}
                  <span
                    className='pointer-event font-bold'
                    onClick={() => handleRemoveInclusion(index)}
                  >
                    <CloseIcon />
                  </span>
                </div>
              )}
            </div>
          </div>

          {/* Others Service Exclusions */}
          <div className="col-12 col-sm-6 mt-3">
            <MultiTagsTextField
              hasLabel={true}
              type="text"
              label={Service_Builder?.exclusions}
              placeholder={Service_Builder?.wifi_free}
              name="exclusions"
              id="exclusions"
              value={currentExclusion}
              onChange={(e) => handleExclusionChange(e.target.value)}
              onClickAddButton={handleAddExclusion}
              // onEnter={(e) => {
              //   if (e.key === "Enter") handleAddExclusion();
              // }}
              color={errors?.exclusions?.required && "danger"}
              errors={errors.exclusions}
            />
            <div className='d-flex flex-wrap mt-2'>
              {otherServiceData?.exclusions?.map((exclusion, index) =>
                <div key={index} className='tag mx-1'>
                  {exclusion}
                  <span
                    className='pointer-event font-bold'
                    onClick={() => handleRemoveExclusion(index)}
                  >
                    <CloseIcon />
                  </span>
                </div>
              )}
            </div>
          </div>

          {/* Others Service Description */}
          <div className="col-12 col-sm-12 mt-3">
            {/* <TextAreaField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.description}
              height={'20vh'}
              placeholder={companyDetails?.description}
              name="description_en"
              value={otherServiceData.description_en}
              onChange={(e) => {
                handleOthersInputsChanges({ key: 'description_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "description_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.description_en?.required && "danger"}
              errors={errors.description_en}
            /> */}
            <EditorField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.description}
              placeholder={Service_Builder?.description}
              value={otherServiceData?.description_en}
              onChange={(e, delta, source, editor) => {
                if (source !== "user") return;
                handleOthersInputsChanges({ key: 'description_en', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "description_en", value: removeHTMLTags(e) },
                    { required: true }
                  ),
                });
              }}
              className={'custom-editor'}
              color={errors?.description_en?.required}
              errors={errors?.description_en}
            />
          </div>

          {/* Others Service Policy */}
          <div className="col-12 col-sm-12 mt-3">
            {/* <TextAreaField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.policy}
              height={'20vh'}
              placeholder={Service_Builder?.policy}
              name="policy_en"
              value={otherServiceData?.policy_en}
              onChange={(e) => {
                handleOthersInputsChanges({ key: 'policy_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "policy_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.policy_en?.required && "danger"}
              errors={errors?.policy_en}
            /> */}
            <EditorField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.policy}
              placeholder={Service_Builder?.policy}
              value={otherServiceData?.policy_en}
              onChange={(e, delta, source, editor) => {
                if (source !== "user") return;
                handleOthersInputsChanges({ key: 'policy_en', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "policy_en", value: removeHTMLTags(e) },
                    { required: true }
                  ),
                });
              }}
              className={'custom-editor'}
              color={errors?.policy_en?.required}
              errors={errors?.policy_en}
            />
          </div>

          {/* TermsAndConditionsField */}
          <div className="col-12 custom-field mt-3">
            {/* <TextAreaField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.termsAndConditions}
              placeholder={Service_Builder?.termsAndConditions}
              height={'126px'}
              value={otherServiceData?.terms_en}
              onChange={(e) => {
                handleOthersInputsChanges({ key: 'terms_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "terms_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }
              }
              color={errors?.terms_en?.required ? "danger" : ""}
              errors={errors?.terms_en}
            /> */}
            <EditorField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.termsAndConditions}
              placeholder={Service_Builder?.termsAndConditions}
              value={otherServiceData?.terms_en}
              onChange={(e, delta, source, editor) => {
                if (source !== "user") return;
                handleOthersInputsChanges({ key: 'terms_en', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "terms_en", value: removeHTMLTags(e) },
                    { required: true }
                  ),
                });
              }}
              className={'custom-editor'}
              color={errors?.terms_en?.required}
              errors={errors?.terms_en}
            />
          </div>

          {/* Others Service Images */}
          <div className="col-12 mt-3">
            <div className='images-holder d-flex align-items-center '>
              <UploadFile
                id={`upload-hotel-image-${generateUniqueID()}`}
                label={Service_Builder?.add_images}
                onChange={(images) => {
                  handleOthersInputsChanges({ key: 'images', value: images || [] })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "images", value: images.length, },
                      { required: true }
                    ),
                  });
                }}
                value={otherServiceData?.images}
                errorMsg={
                  errors?.images?.required
                    ? inventory.viewReservation.Photos + " " + commons.isRequired
                    : false
                }
              />
            </div>
          </div>

        </div>

        {/* Show Pricing Checkbox*/}
        <div className="d-flex align-items-center mb-3">
          <input
            type="checkbox"
            name="showPricing"
            id="showPricing"
            checked={showPricing}
            onChange={() => setShowPricing(!showPricing)}
          />
          <label
            className="form-check-label pointer font-bold text-caption mx-2 rememberme-label"
            htmlFor="showPricing"
          >
            {Service_Builder?.select_pricing}
          </label>
        </div>

        {/* Others Pricing and Taxes*/}
        {showPricing &&
          <DefualtPricesTaxes
            pricingState={{
              price: otherServiceData?.price,
              currency: otherServiceData?.currency,
              taxes: otherServiceData?.taxes,
            }}
            setPricingState={(data) => setOtherServiceData((prevData) => ({ ...prevData, ...data }))}
            onSelectTaxes={(value) => setTaxesIsSelected(value)}
          />}

        {/* Save Button */}
        <div className="col-12 d-flex justify-content-end gap-10 mt-3">
          {status === 'add' &&
            <button
              type='button'
              onClick={() => submit(null, 'submit-add-new')}
              className="submit-addNew-btn "
            >
              <span className="btn-overlay"></span>
              <span className="text d-flex align-items-center gap-10 justify-content-center">
                {Service_Builder?.save_add_new}
              </span>
            </button>
          }

          <button type="submit" className="submit-btn ">
            <span className="btn-overlay"></span>
            <span className="text d-flex align-items-center gap-10 justify-content-center">
              {status === 'edit' ? Service_Builder?.save_changes : Service_Builder?.save_close}
            </span>
          </button>
        </div>

      </form>
    </>
  );
};

export default ServiceBuilderOthersForm;



