import { useEffect, useState } from 'react';
// React-Router-Dom
import { useHistory, useParams } from 'react-router-dom';
// Translation
import Locale from 'translations';
// Componnets
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import TextAreaField from 'components/Form/TextAreaField/TextAreaField';
import UploadFile from 'components/UploadFile';
// Shared Component
import MultiTagsTextField from '../shared/MultiTagsTextField';
import AttractionPricesTaxes from '../shared/AttractionPricesTaxes';
// Context
import { useSBSState } from 'context/global';
// Services
import { fetchCities } from 'services/lookups';
import {
  addAttractionService,
  getServicesById,
  updateService
} from 'services/serviveBuilder';
// Helper
import validate, { isFormValid } from 'helpers/validate';
import generateUniqueID from 'helpers/generateUniqueID';
import useSharedData from '../constant/useSharedData';
// Assets
import { ReactComponent as CloseIcon } from "assets/images/serviceBuilder/x.svg";
// Custom Hooks
import useDidMountEffect from 'hooks/useDidMountEffect ';
import { store } from 'react-notifications-component';
import EditorField from 'components/Form/EditorField/EditorField';
import { removeHTMLTags } from 'modules/WebBuilder-V2/helpers/removeHtmlTag';
//--------------------------------------------------------------------------------------

const ServiceBuilderAttractionForm = () => {
  const initialAttractionData = {
    name_en: "",
    country: null,
    city: null,
    tour_type: "",
    stars: "",
    address: "",
    inclusions: null,
    exclusions: null,
    description_en: "",
    policy_en: "",
    terms_en: "",
    images: [],
    currency: 'SAR',
    adult_price: '',
    child_price: '',
    taxes: [{
      name: '',
      type: '',
      value: '',
    }]
  }
  // *** Hooks
  const { inventory, commons, Service_Builder } = Locale;
  const { allCountries } = useSBSState();
  const history = useHistory();
  const { status, id } = useParams();
  const { requiredAttractionInputs, starsOptions, tourTypesOptions } = useSharedData()


  // **** State
  const [attractionData, setAttractionData] = useState(initialAttractionData);
  const [errors, setErrors] = useState({});
  const [cities, setCities] = useState([]);
  const [showPricing, setShowPricing] = useState(false);
  const [currentInclusion, setCurrentInclusion] = useState("");
  const [currentExclusion, setCurrentExclusion] = useState("");
  const [taxesIsSelected, setTaxesIsSelected] = useState("");
  // const [selectedLang, setSelectedLang] = useState("en");

  function handleAttractionInputsChanges({ key, value }) {
    let attractionDataClone = { ...attractionData }
    attractionDataClone[`${key}`] = value
    if (key === 'country') {
      fetchCity(value?.id)
      setAttractionData({
        ...attractionDataClone,
        city: '',
      })
    } else {
      setAttractionData({ ...attractionDataClone })
    }
  }

  const handleAddInclusion = () => {
    if (currentInclusion.trim()) {
      setAttractionData((prevData) => ({
        ...prevData,
        inclusions: prevData?.inclusions?.length ? [...prevData.inclusions, currentInclusion.trim()] : [currentInclusion.trim()]
      }));
      setCurrentInclusion("");
    }
  };

  const handleAddExclusion = () => {
    if (currentExclusion.trim()) {
      setAttractionData((prevData) => ({
        ...prevData,
        exclusions: prevData?.exclusions?.length ? [...prevData.exclusions, currentExclusion.trim()] : [currentExclusion.trim()]
      }));
      setCurrentExclusion("");
    }
  };

  const handleRemoveInclusion = (index) => {
    setAttractionData((prevData) => ({
      ...prevData,
      inclusions: prevData.inclusions.filter((_, i) => i !== index)
    }));
  };

  const handleRemoveExclusion = (index) => {
    setAttractionData((prevData) => ({
      ...prevData,
      exclusions: prevData.exclusions.filter((_, i) => i !== index)
    }));
  };

  const handleInclusionChange = (value) => {
    setCurrentInclusion(value);
  };

  const handleExclusionChange = (value) => {
    setCurrentExclusion(value);
  };

  const checkFormErrors = () => {
    let submitError = {};
    requiredAttractionInputs.forEach((key) => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: attractionData[key] },
          { required: true }
        )
      }
    });
    setErrors(submitError);
    return submitError;
  };

  const fetchCity = async (id) => {
    const citiesList = await fetchCities(id);
    setCities(citiesList);
  };

  const fetchAttarctionData = async () => {
    const response = await getServicesById(id)
    if (response?.status === 200) {
      setAttractionData({
        serviceId: response?.data?.data?.service.id,
        name_en: response?.data?.data?.service?.name?.en,
        country: response?.data?.data?.service?.country,
        city: response?.data?.data?.service?.city,
        tour_type: response?.data?.data?.service?.tour_type,
        stars: { name: response?.data?.data?.service?.stars, id: response?.data?.data?.service?.stars },
        address: response?.data?.data?.service?.address?.en,
        inclusions: response?.data?.data?.service?.inclusion?.en,
        exclusions: response?.data?.data?.service?.exclusion?.en,
        policy_en: response?.data?.data?.service?.policy?.en,
        description_en: response?.data?.data?.service?.description?.en,
        terms_en: response?.data?.data?.service?.terms?.en,
        images: response?.data?.data?.service?.images,
        adult_price: response?.data?.data?.service?.adult_price,
        child_price: response?.data?.data?.service?.child_price,
        currency: response?.data?.data?.service?.currency,
        taxes: response?.data?.data?.service?.taxes || []
      })
      setShowPricing((response?.data?.data?.service?.adult_price) || (response?.data?.data?.service?.child_price) ? true : false)
    }
    return response
  }

  const submit = async (e, submitType) => {
    e?.preventDefault();
    const formErrors = checkFormErrors();
    if (!isFormValid(formErrors)) {
      return;
    }
    let requestBody = {
      name: { en: attractionData?.name_en },
      country_id: attractionData?.country?.id || attractionData?.country?.country_id,
      city_id: attractionData?.city?.id,
      tour_type: attractionData?.tour_type,
      stars: attractionData?.stars?.id,
      address: { en: attractionData?.address },
      inclusion: { en: attractionData?.inclusions },
      exclusion: { en: attractionData?.exclusions },
      policy: { en: attractionData?.policy_en },
      description: { en: attractionData?.description_en },
      terms: { en: attractionData?.terms_en },
      images: attractionData?.images,
      adult_price: showPricing ? attractionData?.adult_price : null,
      child_price: showPricing ? attractionData?.child_price : null,
      currency: attractionData?.currency,
      // taxes: isPayloadValid(attractionData?.taxes?.[0]) ? attractionData?.taxes : []
      taxes: taxesIsSelected ? attractionData?.taxes : []
    }
    // try {
    //   if (status === 'edit') {
    //     await updateService(attractionData?.serviceId, "attractions", requestBody);
    //   } else {
    //     await addAttractionService(requestBody);
    //   }
    //   history.push('/website/service-builder');
    // } catch (error) {
    //   console.error("Error submitting form:", error);
    // }
    if (status === 'add') {
      const response = await addAttractionService(requestBody);
      if ((response?.status === 200 || response?.status === 201) && submitType === 'submit-add-new') {
        store.addNotification({
          message: Service_Builder?.ServiceAddedSuccessfully,
          title: Service_Builder?.Success,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
        setAttractionData(initialAttractionData)
      }
      if ((response?.status === 200 || response?.status === 201) && !submitType) {
        history.push('/website/service-builder')
      }
    } else if (status === 'edit') {
      const response = await updateService(attractionData?.serviceId, "attractions", requestBody);
      if (response?.status === 200 || response?.status === 201) {
        history.push('/website/service-builder')
      }
    }
  };

  useEffect(() => {
    if (status === 'edit' && id) {
      fetchAttarctionData()
    }
  }, [])

  useDidMountEffect(() => {
    if (attractionData?.exclusions?.length >= 0) {
      setErrors({
        ...errors,
        ...validate(
          { name: "exclusions", value: attractionData?.exclusions?.length },
          { required: true }
        ),
      });
    }
  }, [attractionData?.exclusions])

  useDidMountEffect(() => {
    if (attractionData?.inclusions?.length >= 0) {
      setErrors({
        ...errors,
        ...validate(
          { name: "inclusions", value: attractionData?.inclusions?.length },
          { required: true }
        ),
      });
    }
  }, [attractionData?.inclusions])

  console.log(attractionData)
  // ------- JSX Code -------
  return (
    <>
      <h1 className='form-title'>{Service_Builder?.select_attraction_details}</h1>
      <form onSubmit={submit} className="mt-4">
        <div className="row form-holder mx-auto mb-5">

          {/* Attraction Name en */}
          <div className="col-12 col-md-6 mt-3">
            <TextField
              type="text"
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.attraction_name_en}
              placeholder={Service_Builder?.attraction_name_en}
              name="name_en"
              id="name_en"
              value={attractionData?.name_en}
              onChange={(e) => {
                handleAttractionInputsChanges({ key: 'name_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "name_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.name_en?.required && "danger"}
              errors={errors.name_en}
            />
          </div>

          {/* Attraction Country */}
          <div className="col-12 col-md-3 mt-3">
            <SelectField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.country}
              placeholder={Service_Builder?.country}
              options={allCountries}
              name="country"
              value={attractionData?.country?.name}
              onChange={(e) => {
                handleAttractionInputsChanges({ key: 'country', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "country", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.country?.required && "danger"}
              errors={errors.country}
            />
          </div>

          {/* Attraction City */}
          <div className="col-12 col-md-3 mt-3">
            <SelectField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.city}
              placeholder={Service_Builder?.city}
              options={cities}
              disabled={!attractionData?.country?.name}
              name="city"
              value={attractionData?.city?.name}
              onChange={(e) => {
                handleAttractionInputsChanges({ key: 'city', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "city", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.city?.required && "danger"}
              errors={errors.city}
            />
          </div>

          {/* Tour Type */}
          {/* <div className="col-12 col-md-4 mt-3">
            <SelectField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.tour_type}
              placeholder={Service_Builder?.tour_type}
              options={tourTypesOptions}
              name="tour_type"
              value={attractionData?.tour_type?.name}
              onChange={(e) => {
                handleAttractionInputsChanges({ key: 'tour_type', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "tour_type", value: e },
                    { required: true }
                  ),
                });
              }}
              color={errors?.tour_type?.required && "danger"}
              errors={errors.tour_type}
            />
          </div> */}

          <div className="col-12 col-md-4 mt-3">
            <TextField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.tour_type}
              placeholder={Service_Builder?.tour_type}
              options={tourTypesOptions}
              name="tour_type"
              value={attractionData?.tour_type}
              onChange={(e) => {
                handleAttractionInputsChanges({ key: 'tour_type', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "tour_type", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.tour_type?.required && "danger"}
              errors={errors.tour_type}
            />
          </div>

          {/* Tour Stars */}
          <div className="col-12 col-md-4 mt-3">
            <SelectField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.stars}
              placeholder={Service_Builder?.stars}
              value={attractionData?.stars?.name}
              options={starsOptions}
              color={errors?.stars?.required && "danger"}
              name="stars"
              onChange={(e) => {
                handleAttractionInputsChanges({ key: 'stars', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "stars", value: e },
                    { required: true }
                  ),
                });
              }}
              errors={errors.stars}
            />
          </div>

          {/* Tour Address */}
          <div className="col-12 col-md-4 mt-3">
            <TextField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.address}
              placeholder={Service_Builder?.address}
              name="address"
              type="text"
              id="address"
              value={attractionData?.address}
              onChange={(e) => {
                handleAttractionInputsChanges({ key: 'address', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "address", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.address?.required && "danger"}
              errors={errors.address}
            />
          </div>

          {/* Tour Inclusions */}
          <div className="col-12 col-md-6 mt-3">
            <MultiTagsTextField
              hasLabel={true}
              type="text"
              label={Service_Builder?.inclusions}
              placeholder={Service_Builder?.wifi_free}
              name="inclusions"
              id="inclusions"
              value={currentInclusion}
              onChange={(e) => { handleInclusionChange(e.target.value) }}
              onClickAddButton={handleAddInclusion}
              // onEnter={(e) => {
              //   if (e.key === "Enter") handleAddInclusion();
              // }}
              color={errors?.inclusions?.required && "danger"}
              errors={errors.inclusions}
            />
            <div className='d-flex flex-wrap mt-2'>
              {attractionData?.inclusions?.map((inclusion, index) => (
                <div key={index} className='tag mx-1'>
                  {inclusion}
                  <span
                    className='pointer-event font-bold'
                    onClick={() => handleRemoveInclusion(index)}
                  >
                    <CloseIcon />
                  </span>
                </div>
              ))}
            </div>
          </div>

          {/* Tour Exlusions */}
          <div className="col-12 col-md-6 mt-3">
            <MultiTagsTextField
              type="text"
              hasLabel={true}
              label={Service_Builder?.exclusions}
              placeholder={Service_Builder?.wifi_free}
              name="exclusions"
              id="exclusions"
              value={currentExclusion}
              onChange={(e) => handleExclusionChange(e.target.value)}
              onClickAddButton={handleAddExclusion}
              // onEnter={(e) => {
              //   if (e.key === "Enter") handleAddExclusion();
              // }}
              color={errors?.exclusions?.required && "danger"}
              errors={errors?.exclusions}
            />
            <div className='d-flex flex-wrap mt-2'>
              {attractionData?.exclusions?.map((exclusion, index) => (
                <div key={index} className='tag mx-1'>
                  {exclusion}
                  <span
                    className='pointer-event font-bold'
                    onClick={() => handleRemoveExclusion(index)}
                  >
                    <CloseIcon />
                  </span>
                </div>
              ))}
            </div>
          </div>

          {/* Tour Description */}
          <div className="col-12 col-md-12 mt-3">
            {/* <TextAreaField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.description}
              height={'20vh'}
              placeholder={Service_Builder?.description}
              name="description_en"
              value={attractionData?.description_en}
              onChange={(e) => {
                handleAttractionInputsChanges({ key: 'description_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "description_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.description_en?.required && "danger"}
              errors={errors.description_en}
            /> */}
            <EditorField
              hasLabel={status === 'edit' ? true : false}
              placeholder={Service_Builder?.description}
              label={Service_Builder?.description}
              value={attractionData?.description_en}
              onChange={(e, delta, source, editor) => {
                if (source !== "user") return;
                handleAttractionInputsChanges({ key: 'description_en', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "description_en", value: removeHTMLTags(e) },
                    { required: true }
                  ),
                });
              }}
              className={'custom-editor'}
              color={errors?.description_en?.required}
              errors={errors?.description_en}
            />
          </div>

          {/* Tour Policy */}
          <div className="col-12 col-md-12 mt-3">
            {/* <TextAreaField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.policy}
              height={'20vh'}
              placeholder={Service_Builder?.policy}
              name="policy_en"
              value={attractionData?.policy_en}
              onChange={(e) => {
                handleAttractionInputsChanges({ key: 'policy_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "policy_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }}
              color={errors?.policy_en?.required && "danger"}
              errors={errors?.policy_en}
            /> */}
            <EditorField
              hasLabel={status === 'edit' ? true : false}
              placeholder={Service_Builder?.policy}
              label={Service_Builder?.policy}
              value={attractionData?.policy_en}
              onChange={(e, delta, source, editor) => {
                if (source !== "user") return;
                handleAttractionInputsChanges({ key: 'policy_en', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "policy_en", value: removeHTMLTags(e) },
                    { required: true }
                  ),
                });
              }}
              className={'custom-editor'}
              color={errors?.policy_en?.required}
              errors={errors?.policy_en}
            />
          </div>

          {/* TermsAndConditionsField */}
          <div className="col-12 custom-field mt-3">
            {/* <TextAreaField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.termsAndConditions}
              placeholder={Service_Builder?.termsAndConditions}
              height={'126px'}
              value={attractionData?.terms_en}
              onChange={(e) => {
                handleAttractionInputsChanges({ key: 'terms_en', value: e?.target?.value })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "terms_en", value: e?.target?.value },
                    { required: true }
                  ),
                });
              }
              }
              color={errors?.terms_en?.required ? "danger" : ""}
              errors={errors?.terms_en}
            /> */}
            <EditorField
              hasLabel={status === 'edit' ? true : false}
              label={Service_Builder?.termsAndConditions}
              placeholder={Service_Builder?.termsAndConditions}
              value={attractionData?.terms_en}
              onChange={(e, delta, source, editor) => {
                if (source !== "user") return;
                handleAttractionInputsChanges({ key: 'terms_en', value: e })
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "terms_en", value: removeHTMLTags(e) },
                    { required: true }
                  ),
                });
              }}
              className={'custom-editor'}
              color={errors?.terms_en?.required}
              errors={errors?.terms_en}
            />
          </div>

          {/* Tour Images */}
          <div className="col-12 mt-3">
            <div className='images-holder d-flex align-items-center '>
              <UploadFile
                id={`upload-hotel-image-${generateUniqueID()}`}
                label={Service_Builder?.add_images}
                onChange={(images) => {
                  handleAttractionInputsChanges({ key: 'images', value: images || [] })
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "images", value: images.length, },
                      { required: true }
                    ),
                  });
                }}
                value={attractionData.images}
                errorMsg={
                  errors?.images?.required
                    ? inventory.viewReservation.Photos + " " + commons.isRequired
                    : false
                }
              />
            </div>
          </div>

        </div>

        {/* Show Pricing Checkbox*/}
        <div className="d-flex align-items-center mb-3">
          <input
            type="checkbox"
            name="showPricing"
            id="showPricing"
            checked={showPricing}
            onChange={() => setShowPricing(!showPricing)}
          />
          <label
            className="form-check-label pointer font-bold text-caption mx-2 rememberme-label"
            htmlFor="showPricing"
          >
            {Service_Builder?.select_pricing}
          </label>
        </div>


        {/* Attraction Pricing and Taxes*/}
        {showPricing && (
          <AttractionPricesTaxes
            pricingState={{
              adult_price: attractionData?.adult_price,
              child_price: attractionData?.child_price,
              currency: attractionData?.currency,
              taxes: attractionData?.taxes,
            }}
            setPricingState={(data) => setAttractionData((prevData) => ({ ...prevData, ...data }))}
            onSelectTaxes={(value) => setTaxesIsSelected(value)}
          />
        )}

        {/* Save Button */}
        <div className="col-12 d-flex justify-content-end gap-10 mt-3">
          {status === 'add' &&
            <button
              type='button'
              onClick={() => submit(null, 'submit-add-new')}
              className="submit-addNew-btn "
            >
              <span className="btn-overlay"></span>
              <span className="text d-flex align-items-center gap-10 justify-content-center">
                {Service_Builder?.save_add_new}
              </span>
            </button>
          }

          <button type="submit" className="submit-btn ">
            <span className="btn-overlay"></span>
            <span className="text d-flex align-items-center gap-10 justify-content-center">
              {status === 'edit' ? Service_Builder?.save_changes : Service_Builder?.save_close}
            </span>
          </button>
        </div>

      </form>
    </>
  );
};

export default ServiceBuilderAttractionForm;



